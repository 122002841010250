
// 每页条数
export const pageSize = 10;

// 可选切换条数
export const pageSizes = [10, 20, 50, 100];

//分页数据计算,参total总页数、pageNum当前页，pageSize每页条数
export const getMeta = (total, pageNum, pageSize = 10) => {
    return {
        // 总条数
        total, // 每页条数
        pageSize, // 最后一页,向上取整，保证至少一页
        lastPage: Math.ceil(total / pageSize), //当前页
        pageNum
    }
}

//设置空值
export const setValue = (v) => {
    for (let key in v) {
        if (v[key] === undefined || v[key] === null || v[key] === "") {
            v[key] = "--";
        }
    }
    return v;
}

//跳转,isNew为true打开新页面，false当前页面调，url跳转地址
export const openAction = (url, isNew = true) => {
    if (isNew) {
        window.open(url)
    } else {
        window.location.href = url;
    }
}

//旧机情况
export const getHealthCode = (v) => {
    switch (v) {
        case 'Nice':
            return '靓机';
        case 'Little_Snow':
            return '小花';
        case 'Big_Snow':
            return '大花';
        case 'Out_Burst':
            return '外爆';
        case 'Inner_Burst':
            return '内爆';
        case 'Other':
            return "其他"
        default:
            return '';
    }
}