<!--分页单独封装，传值和事件即可-->
<template>
  <div class="box" :style="{margin: marginNum+'px'}">
    <el-pagination
        @size-change="(v)=>$emit('sizeChange',v)"
        @current-change="(v)=>$emit('pageChange',v)"
        :current-page="meta.pageNum"
        :page-sizes="pageSizes"
        :page-size="meta.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="meta.total">
    </el-pagination>
  </div>
</template>

<script>


import {pageSizes, pageSize} from "@/utils/common";

export default {
  name: "pagination",
  props: {
    meta: {
      type: Object,
      default: () => {
        return {
          // 总条数
          total: 0,
          // 每页条数
          pageSize,
          //当前页
          pageNum: 1
        }
      },
    },
    marginNum: {
      type: Number,
      default: 50
    }
  },
  data() {
    return {
      pageSizes,
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  width: auto;
  float: right;
  margin: 50px;
}
</style>
