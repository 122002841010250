<!--//该组件提供谷歌验证码、单纯文本信息-->
<template>
  <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="show"
      :width="width"
      :before-close="()=>$emit('close')">
    <slot/>
    <!-- 红色提示 -->
    <div class="header_messgae" v-if="headerMessage">
      <slot name="headerMessage"/>
      {{headerMessage}}
    </div>
    <!-- 带原点提示 -->
    <div class="message" v-if="isMessage">
      {{ message }}
      <slot name="message"/>
    </div>
    <!--  顶部提示  -->
    <div v-if="isTip" class="tip">
      {{ tipText }}
    </div>
    <!--  除了动态口令，额外加的表单  -->
    <slot name="from"/>
    <div v-if="isCode" :style="{'width': codeWidth}">
      <el-form>
        <el-form-item :label="codeLabel" :label-width="codeLabelWidth">
          <el-input
              placeholder="请输入谷歌动态口令"
              :value="code"
              @input="(v)=>$emit('codeChange',v)"
              clearable/>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">{{ cancelText }}</el-button>
      <el-button type="primary" @click="$emit('config')" v-loading="loading">{{configText}}</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    // 弹窗标题
    title: {
      type: String,
      default: "操作提示"
    },
    codeLabel: {
      type: String,
      default: "动态口令"
    },
    // 弹窗宽度
    width: {
      type: String,
      default: "30%"
    },

    // code输入label长度
    codeLabelWidth:{
      type:String,
      default:"100px"
    },

    // 弹窗显示控制
    show: {
      type: Boolean,
      default: false
    },

    // 按钮数量
    btnType: {
      // 值为all，代表存在确定和取消，值为one代表单个按钮
      type: String,
      default: "all",
    },
    // 确定按钮文字
    configText: {
      type: String,
      default: "确定"
    },
    // 取消按钮文字
    cancelText: {
      type: String,
      default: "取消"
    },
    // 单个按钮文字
    oneText: {
      type: String,
      default: "知道了"
    },
    // 是否存在提示信息
    isMessage: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ""
    },
    // 是否为谷歌验证码
    isCode: {
      type: Boolean,
      default: false
    },
    // 验证码
    code: {
      type: String,
      default: ""
    },
    // 是否为单纯提示
    isTip: {
      type: Boolean,
      default: false
    },
    // 提示文字
    tipText: {
      type: String,
      default: ""
    },
    headerMessage:{
      type:String,
      default:""
    },
    //加载
    loading:{
      type:Boolean,
      default:false
    },
    // code宽度
    codeWidth:{
      type:String,
      default:"80%"
    }
  },
  // 依次为确定按钮、取消按钮事件,code输入变化时
  emits: ['config', 'close', "codeChange"]
}
</script>
<style scoped lang="scss">
.tip {
  font-size: 14px;
  text-align: center;
  color: #000;
  margin-bottom: 20px;
}

.message {
  font-size: 12px;
  color: #F80000;
  position: relative;
  padding-left: 22px;
  margin-top: 10px;
  text-align: left;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    background: #F80000;
    left: 7px;
    top: 50%;
    transform: translateY(-53%);
  }
}

.header_messgae{
  font-size: 12px;
  color: #F80000;
  position: relative;
  text-align: left;
  margin-bottom: 20px;
}
</style>
