<template>
  <div class="SystemOffer-list">
    <GlobalInfoBar title="商家列表" content="可查看入驻平台的商家基本信息，且可设置门店商户的自主报价回收商返利规则" />
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <ResidentFrom />
      </div>
      <ResidentTable @handleDialog="handleDialog" @handleBtn="handleBtn" />
    </GlobalChunk>
    <DialogTbale @onClose="onClose" :show="deleteVisible" :title="title" :columns="tableColumns" :data="DialogDataList"
                 :currentPage="pageNum" :total="total" :pageSize="10" :width="900" @handleCurrentChange="handleCurrentChange">
      <span style="margin-bottom: 10px" v-if="showAmount">
        <el-select v-model="dialogValue" placeholder="请选择商家类型" @change="dialogSelect" style="margin-bottom: 20px">
          <el-option v-for="item in options" :key="item.merchantId" :label="item.merchantName" :value="item.merchantId">
          </el-option>
        </el-select>
      </span>
      <el-button type="primary" icon="el-icon-plus" style="margin-bottom: 20px; margin-left: 20px" @click="addSetting"
                 v-if="title === '查看返利设置'">
        新增设置
      </el-button>
      <el-table-column label="商家简称" slot="merchantName" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.merchantName }}</div>
          <div v-if="row.isInside">
            <el-tag type="warning" size="small" effect="plain" v-if="row.isInside">内部回收商</el-tag>
          </div>
        </template>
      </el-table-column>
<!--      <el-table-column label="商家地址" slot="operationAddress" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <el-tooltip :content="row.province +-->
<!--            '-' +-->
<!--            row.city +-->
<!--            '-' +-->
<!--            row.region +-->
<!--            '-' +-->
<!--            row.merchantAddress-->
<!--            " placement="top">-->
<!--            <p style="-->
<!--                display: -webkit-box;-->
<!--                -webkit-box-orient: vertical;-->
<!--                -webkit-line-clamp: 2;-->
<!--                overflow: hidden;-->
<!--              ">-->
<!--              {{-->
<!--                row.province +-->
<!--                "-" +-->
<!--                row.city +-->
<!--                "-" +-->
<!--                row.region +-->
<!--                "-" +-->
<!--                row.merchantAddress-->
<!--              }}-->
<!--            </p>-->
<!--          </el-tooltip>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column label="合作状态" slot="applyStatus" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <span>{{-->
<!--              row.applyStatus == "00"-->
<!--                  ? "邀请待同意"-->
<!--                  : row.applyStatus == "01"-->
<!--                      ? "合作中"-->
<!--                      : row.applyStatus == "02"-->
<!--                          ? "邀请未通过"-->
<!--                          : "取消合作"-->
<!--            }}-->
<!--            {{-->
<!--              row.companyIsEnable == "02" &&-->
<!--              row.applyStatus != "00" &&-->
<!--              row.applyStatus != "02"-->
<!--                  ? "(关闭报价)"-->
<!--                  : ""-->
<!--            }}-->
<!--          </span>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column label="合作门店" width="70" slot="configStores" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <span v-if="row.configStores" style="-->
<!--              color: #0981ff;-->
<!--              border-bottom: 1px solid #0981ff;-->
<!--              cursor: pointer;-->
<!--            " @click="configStoresFn(row)">-->
<!--            {{ row.configStores }}-->
<!--          </span>-->
<!--          <span v-else>-->
<!--            {{ row.configStores || "&#45;&#45;" }}-->
<!--          </span>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="报价品类" slot="quoteMachineType" width="160" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <div>{{ row.quoteMachineType == '01' ? '全品类' : '指定品类' }}</div>-->
<!--          <div v-if="row.quoteMachineType == '02' && Array.isArray(row.assignQuoteMachineType)">-->
<!--            <el-tag type="success" style="margin-right:5px" size="mini" v-show="row.assignQuoteMachineType.length"-->
<!--                    v-for="(item, index) in row.assignQuoteMachineType" :key="index">-->
<!--              {{-->
<!--                item == 'tablet' ? '平板' : item == 'other' ? '其他' : item == 'laptop' ? '笔记本' : item == 'mobile' ? '手机' : ''-->
<!--              }}-->
<!--            </el-tag>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="物流状态" slot="logistics" width="110" align="center">
        <template slot-scope="{ row }">
          <div style="color:green" v-if="row.merchantIsOpenLogistics && row.isOpenLogisticsToCompany">已开启</div>
          <div style="color:red" v-if="row.merchantIsOpenLogistics && !row.isOpenLogisticsToCompany">
            <div>未启用</div>
            <div>（商家已启用）</div>
          </div>
          <div style="color:red" v-if="!row.merchantIsOpenLogistics">未启用</div>
        </template>
      </el-table-column>
      <el-table-column label="收货方" slot="receiptType" align="center">
        <template slot-scope="{ row }">
          <div style="color:#666666;">{{ row.receiptType == '01' ? '回收商' : '平台' }}</div>
<!--          <div @click="setReceiptType(row)">-->
<!--            <span style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;">-->
<!--              {{ currentRow.isOut ? '修改' : '查看地址' }}-->
<!--            </span>-->
<!--          </div>-->
        </template>
      </el-table-column>
<!--      <el-table-column label="启用内部回收" slot="isInside" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <el-switch inactive-color="#bebebe" @change="switchInsideBiz(row)" v-model="row.isInside"></el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="服务费设置" slot="serverFee" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <div style="color:#666666;">-->
<!--            {{ row.isShowQuoteChannelServerFee ? row.quoteChannelServerFee + '%' : '未设置' }}-->
<!--          </div>-->
<!--          <div @click="setServerFee(row)"><span-->
<!--              style="border-bottom:1px solid #0981ff;color:#0981ff;cursor: pointer;">设置</span>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="操作" width="140" slot="offerOperation" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <el-button size="mini" :type="row.companyIsEnable == '01' ? 'primary' : 'danger'" @click="offerOperation(row)">-->
<!--            {{ row.companyIsEnable == '01' ? '关闭报价' : '开启报价' }}-->
<!--          </el-button>-->
<!--          <el-button v-if="!row.merchantIsMatrix" style="margin-top: 10px" size="mini" type='warning' @click="setQuotecategory(row)"> 设置报价品类-->
<!--          </el-button>-->
<!--          <el-button v-if="currentRow.isOut" style="margin-top: 10px" size="mini"-->
<!--                     :type="row.isAssign ? 'info' : 'warning'" @click="setAssign(row)">{{ row.isAssign ? '关闭指定报价' : '设置指定报价' }}-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <!--      <el-table-column label="联系电话" slot="operationAddress" align="center">-->
      <!--        <template slot-scope="{ row }">-->
      <!--          <el-tooltip :content="row.phone" placement="top">-->
      <!--            <span v-if="row.phone==null">&#45;&#45;</span>-->
      <!--            <span v-else-if="row.phone!=null">{{ row.phone }}</span>-->
      <!--          </el-tooltip>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column label="商家地址" slot="operationAddress" align="center">-->
      <!--        <template slot-scope="{ row }">-->
      <!--          <el-tooltip :content="row.merchantAddress" placement="top">-->
      <!--            <p style="display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;">-->
      <!--              {{ row.merchantAddress }}</p>-->
      <!--          </el-tooltip>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="价格区间" slot="priceRange" align="center">
        <template slot-scope="{ row }">
          <div v-if="!row.edit">{{ row.startQuote }}-{{ row.endQuote }}</div>
          <template v-else>
            <el-input v-model.number="row.startQuote" size="mini" style="width: 100px"></el-input>
            -
            <el-input v-model.number="row.endQuote" size="mini" style="width: 100px"></el-input>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="苹果返利" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-radio name="android" v-model="row.iphoneType" label="02" :disabled="!row.edit">
            固定金额
            <el-input v-if="row.edit" v-model.number="row.iphoneAmount" size="mini" style="width: 100px"></el-input>
            <span v-else>{{ row.iphoneAmount }}</span>
          </el-radio>

          <el-radio name="android" v-model="row.iphoneType" label="01" :disabled="!row.edit">按比例
            <el-input v-if="row.edit" v-model.number="row.iphoneRatio" size="mini" style="width: 50px"></el-input>
            <span v-else>{{ row.iphoneRatio }}</span>
            %
          </el-radio>
        </template>
      </el-table-column>
      <el-table-column label="安卓返利" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-radio name="android" v-model="row.androidType" label="02" :disabled="!row.edit">
            固定金额
            <el-input v-if="row.edit" v-model.number="row.androidAmount" size="mini" style="width: 100px"></el-input>
            <span v-else>{{ row.androidAmount }}</span>
          </el-radio>

          <el-radio name="android" v-model="row.androidType" label="01" :disabled="!row.edit">按比例
            <el-input v-if="row.edit" v-model.number="row.androidRatio" size="mini" style="width: 50px"></el-input>
            <span v-else>{{ row.androidRatio }}</span>
            %
          </el-radio>
        </template>
      </el-table-column>

      <el-table-column label="操作" slot="operate" align="center" width="150px">
        <template slot-scope="{ row }">
          <div class="dialog-btns">
            <span type="text" @click="handleEdit(row)" class="dialog-btn edit" v-if="!row.edit">编辑</span>
            <span type="text" @click="handleSave(row)" class="dialog-btn save" v-if="row.edit">保存</span>
            <span type="text" @click="handleDelete(row)" class="dialog-btn delete">删除</span>
          </div>
        </template>
      </el-table-column>
    </DialogTbale>

    <DialogTbale @onClose="onStoresClose" :show="configStoreshow" title="门店列表" :columns="configStorestableColumns"
                 :data="configStoresdata" :currentPage="StorespageNum" :total="Storestotal" :pageSize="10" :width="1500"
                 @handleCurrentChange="handleStoresChange">

      <el-table-column label="门店店长" slot="storeManager" align="center">
        <template slot-scope="{ row }">
          <!-- <el-button type="primary" size="mini" @click="FN(row)"
            >新增门店</el-button
          > -->
          <span>{{ row.storeManager || '--' }}</span>
        </template>
      </el-table-column>

      <el-table-column label="店长联系方式" slot="storeManagerPhone" align="center">
        <template slot-scope="{ row }">
          <!-- <el-button type="primary" size="mini" @click="FN(row)"
            >新增门店</el-button
          > -->
          <span>{{ row.storeManagerPhone || '--' }}</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <el-dialog :title='insideTitle' :visible.sync="insideDialogVisible" :close-on-click-modal="false" width="500px"
               @closed="insideClosed">
      <div class="enable-tip" v-if="insideType">
        <div>说明：启用内部回收的商家，在门店使用内部加价时需要进行加价审批。</div>
        <div class="tip-sure">是否确认启用该商家为门店的内部回收商？</div>
      </div>
      <div class="enable-tip" v-else>
        <div>说明：关闭后该门店商家员工不可再向该回收商发送内部加价申请。</div>
        <div class="tip-sure">是否不再使用该回收商作为门店的内部回收商？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="insideClosed">取消</el-button>
        <el-button type="primary" :loading="insideBtnLoading" @click.native="insideSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="relationRow.companyIsEnable == '01' ? '关闭报价提示' : '开启报价提示'" :visible.sync="offerDialogShow"
               :close-on-click-modal="false" width="450px" @close="offerDialogClose">
      <div v-if="currentRow.payApplyStatus != '02' || relationRow.companyIsEnable == '01'">
        <div class="enable-tip" v-if="relationRow.companyIsEnable == '01'">
          <div>说明：关闭报价后，该商家不再参与门店的报价。</div>
          <div class="tip-sure" style="text-align: left;">是否确认关闭<span style="color:#0981FF">{{ relationRow.merchantName
            }}</span>的报价？
          </div>
        </div>
        <div class="enable-tip" v-else>
          <div>说明：开启报价后，商家将继续参与门店商家的报价。</div>
          <div class="tip-sure" style="text-align: left;">是否确认开启<span style="color:#0981FF">{{ relationRow.merchantName
            }}</span>的报价？
          </div>
        </div>
      </div>
      <div class="enable-tip" style="margin-bottom:0;"
           v-if="relationRow.companyIsEnable == '02' && currentRow.payApplyStatus == '02'">
        <div>说明：商家已启用线上支付，请选择手续费承担方</div>
      </div>
      <el-radio-group v-if="currentRow.payApplyStatus == '02' && relationRow.companyIsEnable == '02'" v-model="procedures"
                      style="margin-top: 18px; margin-bottom: 22px">
        <el-radio label="01">门店承担手续费</el-radio>
        <el-radio label="02">回收商承担手续费</el-radio>
      </el-radio-group>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="command = ''; offerDialogShow = false;">取消</el-button>
        <el-button type="primary" :loading="offerDialogBtnLoading" @click.native="offerDialogSure">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title='设置服务费' :visible.sync="serverFeeDialogVisible" :close-on-click-modal="false" width="500px"
               @closed="serverFeeClosed">
      <div class="enable-tip">
        <div>说明：设置后，服务费将在回收端报价时显示。</div>
      </div>
      <div class="command">
        <div style="width: 190px;">报价是否显示渠道服务费：</div>
        <el-radio-group v-model="isShowQuoteChannelServerFee">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </div>
      <div class="command" v-if="isShowQuoteChannelServerFee">
        <div style="width: 120px;">设置服务费：</div>
        <el-input placeholder="输入服务费" v-model="quoteChannelServerFee" @input="numberCheckData('quoteChannelServerFee')">
          <template slot="append">%</template>
        </el-input>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="serverFeeDialogVisible = false">取消</el-button>
        <el-button type="primary" :loading="serverFeeBtnLoading" @click.native="serverFeeSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title='收货方设置' :visible.sync="receiptTypeDialogVisible" :close-on-click-modal="false" width="500px"
               @closed="receiptTypeClosed">
      <div class="enable-tip">
        <div>
          说明：默认回收商收货，地址取回收商配置好的地址。若为平台收货，则发货时地址为平台设置的收货地址（采货侠订单在确认成交时返回平台地址）。
        </div>
      </div>
      <div class="command">
        <div style="width: 100px;">收货方设置</div>
        <el-radio-group v-model="receiptType" @change="receiptTypeChange">
          <el-radio label="01">回收商</el-radio>
          <el-radio label="02">平台</el-radio>
        </el-radio-group>
      </div>
      <div class="address-box" v-if="addressObj">
        <div class="mb-10" style="color:#0981FF;font-size:16px">收件人信息</div>
        <div style="margin-bottom:5px">{{ addressObj.contactName }}-{{ addressObj.contactPhone }}</div>
        <div>{{ addressObj.merchantAddress }}{{ addressObj.houseAddress ? '-' + addressObj.houseAddress : '' }}</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="receiptTypeDialogVisible = false">取消</el-button>
        <el-button type="primary" :loading="receiptTypeBtnLoading" @click.native="receiptTypeSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 分发商家地址 -->
    <el-dialog title="查看收货地址" width="500px" :visible.sync="distributeShow" :close-on-click-modal="false"
               @close="distributeShow = false">
      <div class="address-box" v-if="addressObj">
        <div class="mb-10" style="color:#0981FF;font-size:16px">收件人信息</div>
        <div style="margin-bottom:5px">{{ addressObj.contactName }}-{{ addressObj.contactPhone }}</div>
        <div>{{ addressObj.merchantAddress }}{{ addressObj.houseAddress ? '-' + addressObj.houseAddress : '' }}</div>
      </div>
      <div class="address-box" v-else>暂未绑定收货地址</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="distributeShow = false">取消</el-button>
        <el-button type="primary" @click.native="distributeShow = false">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="relationRow.isAssign ? '关闭指定报价商家' : '设置指定报价商家'" :visible.sync="setAssignShow"
               :close-on-click-modal="false" width="450px" @close="setAssignDialogClose">
      <div class="enable-tip" v-if="relationRow.isAssign">
        <div>说明：关闭后外部商家不再参与同串号订单的指定报价</div>
        <div class="tip-sure">是否确定关闭该商家指定报价？</div>
      </div>
      <div class="enable-tip" v-if="!relationRow.isAssign">
        <div>说明：启用后外部商家同串号重新下单后，若触发了系统自动报价，在报价的同时，需要将订单推送给指定回收商报价</div>
        <div class="tip-sure">是否确定设置为外部渠道的指定报价商家？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="setAssignShow = false">取消</el-button>
        <el-button type="primary" :loading="assignBtnLoading" @click.native="assignSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 报价品类设置 -->
    <el-dialog title='报价品类配置' :visible.sync="SetcategoryDloding" :close-on-click-modal="false" width="1000px"
               @closed="receiptTypeClosed">
      <div>
        <div class="dis_flex al_c set_top_ck"><span>选择报价分类/品牌</span>
          <div class="enable-tip" style="margin-bottom: 0;font-size: 12px;">(说明：按照分类配置回收商报价品牌，默认全选)</div>
        </div>
        <div class="categorySet">
          <div class="cs_center dis_flex al_c">
            <el-checkbox :indeterminate="mobileisIndeterminate" v-model="mobilecheckAll"
                         @change="handleCheckAllChange">手机</el-checkbox>
          </div>
          <div style="margin: 15px 0;"></div>
          <div style="padding: 0 25px;">
            <el-checkbox-group v-model="mobilecheckedCities" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="city in mobilecities" :label="city.name" :key="city.name">{{ city.name }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="categorySet">
          <div class="cs_center dis_flex al_c">
            <el-checkbox :indeterminate="tabletisIndeterminate" v-model="tabletcheckAll"
                         @change="tablethandleCheckAllChange">平板</el-checkbox>
          </div>
          <div style="margin: 15px 0;"></div>
          <div style="padding: 0 25px;">
            <el-checkbox-group v-model="tabletcheckedCities" @change="tablethandleCheckedCitiesChange">
              <el-checkbox v-for="city in tabletcities" :label="city.name" :key="city.name">{{ city.name }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="categorySet">
          <div class="cs_center dis_flex al_c">
            <el-checkbox :indeterminate="laptopisIndeterminate" v-model="laptopcheckAll"
                         @change="laptophandleCheckAllChange">笔记本</el-checkbox>
          </div>
          <div style="margin: 15px 0;"></div>
          <div style="padding: 0 25px;">
            <el-checkbox-group v-model="laptopcheckedCities" @change="laptophandleCheckedCitiesChange">
              <el-checkbox v-for="city in laptopcities" :label="city.name" :key="city.name">{{ city.name }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="categorySet">
          <div class="cs_center dis_flex al_c">
            <el-checkbox :indeterminate="otherisIndeterminate" v-model="othercheckAll"
                         @change="otherhandleCheckAllChange">其他</el-checkbox>
          </div>
          <div style="margin: 15px 0;"></div>
          <div style="padding: 0 25px;">
            <el-checkbox-group v-model="othercheckedCities" @change="otherhandleCheckedCitiesChange">
              <el-checkbox v-for="city in othercities" :label="city.name" :key="city.name">{{ city.name }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div>
        <div class="dis_flex al_c set_top_ck"><span>选择开机情况</span></div>
        <div class="categorySet">
          <div class="cs_center dis_flex al_c">
            <el-checkbox :indeterminate="quoteMachineConditionisIndeterminate" v-model="quoteMachineConditioncheckAll"
                         @change="quoteMachineConditionallChange">全选</el-checkbox>
          </div>
          <div style="margin: 15px 0;"></div>
          <div style="padding: 0 25px;">
            <el-checkbox-group v-model="quoteMachineConditioncheckedCities" @change="quoteMachineConditionChange">
              <el-checkbox v-for="city in quoteMachineConditioncities" :label="city.value" :key="city.value">{{ city.value
                }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div>
        <div class="dis_flex al_c set_top_ck"><span>选择旧机使用情况</span></div>
        <div class="categorySet">
          <div class="cs_center dis_flex al_c">
            <el-checkbox :indeterminate="quoteMachineHealthisIndeterminate" v-model="quoteMachineHealthcheckAll"
                         @change="quoteMachineHealthallChange">全选</el-checkbox>
          </div>
          <div style="margin: 15px 0;"></div>
          <div style="padding: 0 25px;">
            <el-checkbox-group v-model="quoteMachineHealthcheckedCities" @change="quoteMachineHealthChange">
              <el-checkbox v-for="city in quoteMachineHealthcities" :label="city.value" :key="city.value">{{ city.value
                }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px;">
        <div style="margin-bottom: 20px;">最近修改人：{{
            allQuteData.setQuoteAdminAcc ? allQuteData.setQuoteAdminAcc + '-' + allQuteData.setQuoteAdminName : "--" }}
        </div>
        <div>修改时间：{{ allQuteData.setQuoteAdminTime || "--" }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="SetcategoryDloding = false">取消</el-button>
        <el-button type="primary" :loading="receiptTypeBtnLoading" @click.native="SetcategorySubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title='提示' :visible.sync="quotationDialogVisible" :close-on-click-modal="false" width="500px"
               @closed="command = ''">
      <div style="text-align: center;font-size: 16px;margin-bottom: 20px;"> 是否确认保存报价配置？</div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="quotationDialogVisible = false">取消</el-button>
        <el-button type="primary" :loading="receiptTypeBtnLoading" @click.native="quotationSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import ResidentFrom from "./From";
import ResidentTable from "./Table";

export default {
  name: "SystemOffer-list",
  components: { ResidentFrom, ResidentTable },
  data() {
    return {
      allQuteData: {},
      contactmerchantId: "",
      quotationDialogVisible: false,
      //手机
      mobilecheckAll: false,
      mobilecheckedCities: [],
      mobilecities: [],
      mobileisIndeterminate: true,
      mobileOptions: [],
      //平板
      tabletcheckAll: false,
      tabletcheckedCities: [],
      tabletcities: [],
      tabletisIndeterminate: true,
      tabletOptions: [],
      //笔记本
      laptopcheckAll: false,
      laptopcheckedCities: [],
      laptopcities: [],
      laptopisIndeterminate: true,
      laptopOptions: [],
      //其他
      othercheckAll: false,
      othercheckedCities: [],
      othercities: [],
      otherisIndeterminate: true,
      otherOptions: [],
      //选择报价机况
      quoteMachineConditioncheckAll: false,
      quoteMachineConditioncheckedCities: [],
      quoteMachineConditioncities: [],
      quoteMachineConditionisIndeterminate: true,
      quoteMachineConditionOptions: [],
      //选择报价机况
      quoteMachineHealthcheckAll: false,
      quoteMachineHealthcheckedCities: [],
      quoteMachineHealthcities: [],
      quoteMachineHealthisIndeterminate: true,
      quoteMachineHealthOptions: [],
      //报价品类配置弹窗
      SetcategoryDloding: false,
      // 分发弹窗
      distributeShow: false,
      clustersofstars: '',
      StoresmerchantId: '',
      StorespageNum: 1,
      Storestotal: 0,
      configStoreshow: false,
      showAmount: false,
      companyName: "",
      contact: "",
      phone: "",
      //弹窗下拉
      options: [],
      dialogValue: "",
      navShow: false,
      deleteVisible: false,
      title: "",
      DialogDataList: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      companyId: "",
      merchantId: "",
      merchantCompanyId: "",
      tableColumns: [],
      configStorestableColumns: [
        { label: "门店名称", prop: "storeName" },
        { label: "门店地址", prop: "storeAddress" },
        // { label: "店长姓名", prop: "storeManager" },
        // // {slotName: 'operationAddress'},
        // { label: "店长联系方式", prop: "storeManagerPhone" },
        { slotName: "storeManager" },
        { slotName: "storeManagerPhone" },
        { label: "店员数量", prop: "staffCount" },
        { label: "添加时间", prop: "createTime" },
      ],
      configStoresdata: [],
      insideType: null,
      insideTitle: "",
      rowCId: "",
      rowMId: "",
      insideDialogVisible: false,
      command: "",
      insideBtnLoading: false,
      offerDialogShow: false,
      procedures: "",
      currentRow: {},
      relationRow: {},
      offerDialogBtnLoading: false,
      serverFeeDialogVisible: false,
      serverFeeBtnLoading: false,
      receiptTypeBtnLoading: false,
      isShowQuoteChannelServerFee: false,
      quoteChannelServerFee: "",
      receiptTypeDialogVisible: false,
      receiptType: "01",
      addressObj: null,
      assignBtnLoading: false,
      setAssignShow: false
    };
  },
  created() {
    this.$store.commit("tagsView/ROUTER_PATH", this.$route.path);
  },
  computed: {
    dialogParams() {
      return this.$store.state.tagsView.dialogParams;
    },
  },
  methods: {
    //设置报价品类
    setQuotecategory(row) {
      this.contactmerchantId = row.merchantId
      this.getQuoteAssignTemp()
    },
    //获取报价配置模板
    getQuoteAssignTemp() {
      //清空
      this.resetalllist()
      _api.getQuoteAssignTemp({
        companyId: this.clustersofstars,//用商家列表的
        merchantId: this.contactmerchantId,//用关联商家的
      }).then(res => {
        this.allQuteData = res.data
        //手机分类
        this.mobilecities = res.data.mobile.brands
        res.data.mobile.brands.forEach(item => {
          this.mobileOptions.push(item.name)
          if (item.selected) {
            this.mobilecheckedCities.push(item.name)
          }
        })
        //平板分类
        this.tabletcities = res.data.tablet.brands
        res.data.tablet.brands.forEach(item => {
          this.tabletOptions.push(item.name)
          if (item.selected) {
            this.tabletcheckedCities.push(item.name)
          }
        })
        //笔记本分类
        this.laptopcities = res.data.laptop.brands
        res.data.laptop.brands.forEach(item => {
          this.laptopOptions.push(item.name)
          if (item.selected) {
            this.laptopcheckedCities.push(item.name)
          }
        })
        //其他分类
        this.othercities = res.data.other.brands
        res.data.other.brands.forEach(item => {
          this.otherOptions.push(item.name)
          if (item.selected) {
            this.othercheckedCities.push(item.name)
          }
        })
        //选择报价机况
        this.quoteMachineConditioncities = res.data.quoteMachineCondition
        res.data.quoteMachineCondition.forEach(item => {
          this.quoteMachineConditionOptions.push(item.value)
          if (item.selected) {
            this.quoteMachineConditioncheckedCities.push(item.value)
          }
        })
        //选择旧机使用情况
        this.quoteMachineHealthcities = res.data.quoteMachineHealth
        res.data.quoteMachineHealth.forEach(item => {
          this.quoteMachineHealthOptions.push(item.value)
          if (item.selected) {
            this.quoteMachineHealthcheckedCities.push(item.value)
          }
        })
        this.SetcategoryDloding = true
      })
    },
    //清空报价品类
    resetalllist() {
      //手机
      this.mobilecheckAll = false
      this.mobilecheckedCities = []
      this.mobilecities = []
      this.mobileisIndeterminate = true
      this.mobileOptions = []
      //平板
      this.tabletcheckAll = false
      this.tabletcheckedCities = []
      this.tabletcities = []
      this.tabletisIndeterminate = true
      this.tabletOptions = []
      //笔记本
      this.laptopcheckAll = false
      this.laptopcheckedCities = []
      this.laptopcities = []
      this.laptopisIndeterminate = true
      this.laptopOptions = []
      //其他
      this.othercheckAll = false
      this.othercheckedCities = []
      this.othercities = []
      this.otherisIndeterminate = true
      this.otherOptions = []
      //选择报价机况
      this.quoteMachineConditioncheckAll = false
      this.quoteMachineConditioncheckedCities = []
      this.quoteMachineConditioncities = []
      this.quoteMachineConditionisIndeterminate = true
      this.quoteMachineConditionOptions = []
      //选择报价机况
      this.quoteMachineHealthcheckAll = false
      this.quoteMachineHealthcheckedCities = []
      this.quoteMachineHealthcities = []
      this.quoteMachineHealthisIndeterminate = true
      this.quoteMachineHealthOptions = []
    },
    //确认保存报价配置
    quotationSubmit() {
      if (!this.command) {
        this.$message.error('请输入动态口令')
        return
      }
      let QuoteConfigparams = {
        "assignQuoteMachineCondition": [],//	指定报价旧机机况
        "assignQuoteMachineHealth": [],//指定报价旧机使用情况
        "command": this.command,//谷歌验证码
        'companyId': this.clustersofstars,//用商家列表的
        'merchantId': this.contactmerchantId,//用关联商家的
        "laptopBrandIds": [],//	笔记本-品牌id
        "mobileBrandIds": [],//	手机-品牌id
        "otherBrandIds": [],//其他-品牌id
        "tabletBrandIds": [],//平板-品牌id
      }
      console.log(this.quoteMachineConditioncheckedCities);
      this.quoteMachineHealthcheckedCities.forEach(itema => {
        this.quoteMachineHealthcities.forEach(itemb => {
          if (itema == itemb.value) {
            QuoteConfigparams.assignQuoteMachineHealth.push(itemb.key)
          }
        })
      })
      this.quoteMachineConditioncheckedCities.forEach(itema => {
        this.quoteMachineConditioncities.forEach(itemb => {
          if (itema == itemb.value) {
            QuoteConfigparams.assignQuoteMachineCondition.push(itemb.key)
          }
        })
      })
      console.log(QuoteConfigparams.assignQuoteMachineCondition);
      this.laptopcheckedCities.forEach(itema => {
        this.laptopcities.forEach(itemb => {
          if (itema == itemb.name) {
            QuoteConfigparams.laptopBrandIds.push(itemb.id)
          }
        })
      })
      this.mobilecheckedCities.forEach(itema => {
        this.mobilecities.forEach(itemb => {
          if (itema == itemb.name) {
            QuoteConfigparams.mobileBrandIds.push(itemb.id)
          }
        })
      })
      this.tabletcheckedCities.forEach(itema => {
        this.tabletcities.forEach(itemb => {
          if (itema == itemb.name) {
            QuoteConfigparams.tabletBrandIds.push(itemb.id)
          }
        })
      })
      this.othercheckedCities.forEach(itema => {
        this.othercities.forEach(itemb => {
          if (itema == itemb.name) {
            QuoteConfigparams.otherBrandIds.push(itemb.id)
          }
        })
      })
      console.log(QuoteConfigparams);
      this.receiptTypeBtnLoading = true
      //保存合作报价配置
      _api.saveMiddleQuoteConfig(QuoteConfigparams).then(res => {
        this.receiptTypeBtnLoading = false
        this.quotationDialogVisible = false
        this.SetcategoryDloding = false
        this.command = ""
        this.$message({
          message: '配置成功！',
          type: 'success'
        });
        this.handleCurrentChange(this.pageNum);
      }).catch(ree => {
        this.receiptTypeBtnLoading = false
      })
    },
    //保存合作报价配置
    SetcategorySubmit() {
      if (!this.quoteMachineHealthcheckedCities.includes('其他')) {
        this.$message.error('旧机使用情况必须选择其他选项')
        return
      }
      console.log(this.quoteMachineConditioncheckedCities,this.mobilecheckedCities);
      if (this.quoteMachineConditioncheckedCities.includes('功能机/老年机')&&!this.mobilecheckedCities.includes('其他')) {
        this.$message.error('报价机况有功能机/老年机的,手机品牌必须选择其他选项！')
        return
      }
      this.quotationDialogVisible = true
    },
    //手机
    handleCheckAllChange(val) {
      console.log(val);
      this.mobilecheckedCities = val ? this.mobileOptions : [];
      this.mobileisIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      console.log(value);
      let checkedCount = value.length;
      this.mobilecheckAll = checkedCount === this.mobilecities.length;
      this.mobileisIndeterminate = checkedCount > 0 && checkedCount < this.mobilecities.length;
    },
    //平板
    tablethandleCheckAllChange(val) {
      console.log(val);
      this.tabletcheckedCities = val ? this.tabletOptions : [];
      this.tabletisIndeterminate = false;
    },
    tablethandleCheckedCitiesChange(value) {
      console.log(value);
      let tabletcheckedCount = value.length;
      this.tabletcheckAll = tabletcheckedCount === this.tabletcities.length;
      this.tabletisIndeterminate = tabletcheckedCount > 0 && tabletcheckedCount < this.tabletcities.length;
    },
    //笔记本
    laptophandleCheckAllChange(val) {
      console.log(val);
      this.laptopcheckedCities = val ? this.laptopOptions : [];
      this.laptopisIndeterminate = false;
    },
    laptophandleCheckedCitiesChange(value) {
      console.log(value);
      let laptopcheckedCount = value.length;
      this.laptopcheckAll = laptopcheckedCount === this.laptopcities.length;
      this.laptopisIndeterminate = laptopcheckedCount > 0 && laptopcheckedCount < this.laptopcities.length;
    },
    //其他
    otherhandleCheckAllChange(val) {
      console.log(val);
      this.othercheckedCities = val ? this.otherOptions : [];
      this.otherisIndeterminate = false;
    },
    otherhandleCheckedCitiesChange(value) {
      console.log(value);
      let othercheckedCount = value.length;
      this.othercheckAll = othercheckedCount === this.othercities.length;
      this.otherisIndeterminate = othercheckedCount > 0 && othercheckedCount < this.othercities.length;
    },
    //选择报价机况
    quoteMachineConditionallChange(val) {
      console.log(val);
      if (!this.mobilecheckedCities.includes('其他')&&val) {
        this.mobilecheckedCities.push('其他')
      }
      this.quoteMachineConditioncheckedCities = val ? this.quoteMachineConditionOptions : [];
      this.quoteMachineConditionisIndeterminate = false;
    },
    quoteMachineConditionChange(value) {
      console.log(value);
      if (value[0] == '功能机/老年机' && !this.mobilecheckedCities.includes('其他')) {
        this.mobilecheckedCities.push('其他')
      }
      let quoteMachineConditionCount = value.length;
      this.quoteMachineConditioncheckAll = quoteMachineConditionCount === this.quoteMachineConditioncities.length;
      this.quoteMachineConditionisIndeterminate = quoteMachineConditionCount > 0 && quoteMachineConditionCount < this.quoteMachineConditioncities.length;
    },
    //选择旧机使用情况
    quoteMachineHealthallChange(val) {
      console.log(val);
      this.quoteMachineHealthcheckedCities = val ? this.quoteMachineHealthOptions : [];
      this.quoteMachineHealthisIndeterminate = false;
    },
    quoteMachineHealthChange(value) {
      console.log(value);
      let quoteMachineHealthCount = value.length;
      this.quoteMachineHealthcheckAll = quoteMachineHealthCount === this.quoteMachineHealthcities.length;
      this.quoteMachineHealthisIndeterminate = quoteMachineHealthCount > 0 && quoteMachineHealthCount < this.quoteMachineHealthcities.length;
    },
    onStoresClose() {
      this.configStoreshow = false;
      this.StorespageNum = 1;
    },
    //合作门店弹窗
    configStoresFn(row) {
      this.configStoreshow = true;
      this.StoresmerchantId = row.merchantId;
      this.StorespageNum = 1;
      _api
          .findInquiryMerchant({ merchantId: row.merchantId, companyId: this.clustersofstars, pageNum: 1, pageSize: 10 })
          .then((res) => {
            console.log(res, "门店");
            this.configStoresdata = res.data.records;
            this.StorespageNum = res.data.current;
            this.Storestotal = res.data.total;
          });
    },

    handleStoresChange(val) {
      if (val) {
        this.StorespageNum = val;
      } else {
        this.StorespageNum = 1;
      }

      _api
          .findInquiryMerchant({
            merchantId: this.StoresmerchantId,
            companyId: this.clustersofstars,
            pageNum: this.StorespageNum,
            pageSize: 10
          })
          .then((res) => {
            console.log(res, "门店");
            this.configStoresdata = res.data.records;
            this.StorespageNum = res.data.current;
            this.Storestotal = res.data.total;
          });

    },

    handleBtn(val) {
      this.title = "查看返利设置";
      if (this.dialogValue) {
        this.options = [];
        this.dialogValue = "";
      }
      this.showAmount = true;
      this.DialogDataList = [];
      this.tableColumns = [];
      this.total = 0;
      this.deleteVisible = true;
      this.navShow = true;
      this.companyId = val.id;
      const params = {
        companyId: this.companyId,
        merchantId: this.merchantId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      _api.getMiddleAdoptMerchant(params).then((res) => {
        if (res.code === 1) {
          this.tableColumns = [
            { slotName: "priceRange" },
            { slotName: "operation" },
            { slotName: "operate" },
          ];
          this.options = res.data;
          this.dialogSelect();
        }
      });
    },
    dialogSelect(val) {
      let index = 0;
      //merchantCompanyId
      if (!val) {
        this.dialogValue = this.options[0].merchantName;
        this.merchantId = this.options[0].merchantId;
      } else {
        this.merchantId = val;
        this.options.forEach((item, i) => {
          if (item.merchantId === val) {
            index = i;
          }
        });
      }
      this.merchantCompanyId = this.options[index].merchantCompanyId;

      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        companyId: this.companyId,
        merchantId: this.merchantId,
      };
      _api.unionMerchantProfit(params).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;

          console.log(this.DialogDataList);
          this.total = res.data.total;
        }
      });
    },
    // 表格切换页面
    handleCurrentChange(val) {
      console.log(val);
      if (this.title === "关联商家") {
        this.pageNum = val;
        this.companyId = this.dialogParams.companyId;
        const params = {
          companyId: this.companyId,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        this.unionList(params);
      } else {
        const paramsGL = {
          pageNum: val,
          pageSize: this.pageSize,
          companyId: this.companyId,
          merchantId: this.merchantId,
        };
        _api.unionMerchantProfit(paramsGL).then((res) => {
          if (res.code === 1) {
            this.DialogDataList = res.data.records;
            this.total = res.data.total;
          }
        });
      }
    },
    //关联商家弹窗
    handleDialog(vals) {
      this.currentRow = vals
      this.title = "关联商家";
      this.DialogDataList = [];
      this.total = 0;
      this.deleteVisible = true;
      this.showAmount = false;
      this.tableColumns = [
        { slotName: "merchantName" },
        // { label: "联系人", prop: "contact" },
        // { label: "联系电话", prop: "phone" },
        { slotName: "operationAddress" },
        // { label: "入驻时间", prop: "createTime" },
        { label: "首次合作时间", prop: "firstAgreeTime" },
        // { label: "累计交易（笔）", prop: "recycles" },
        { slotName: "applyStatus" },
        { slotName: "configStores" },
        { slotName: "quoteMachineType" },
        { slotName: "logistics" },
        { slotName: "receiptType" },
        { slotName: "isInside" },
        { slotName: "serverFee" },
        { slotName: "offerOperation" },
      ];
      this.clustersofstars = vals.id;
      this.pageNum = 1;
      const params = {
        companyId: vals.id,
        pageNum: this.pageNum,
        // pageSize:2
        applyStatus:"01",
        companyIsEnable:"01"
      };
      this.$store.commit("tagsView/DIALOG_PARAMS", params);
      console.log("unionList");
      this.unionList(params);
      console.log(this.DialogDataList, "handleDialog");
    },
    //关联商家列表请求
    unionList(params) {
      _api.unionMerchantList(params).then((res) => {
        if (res.code === 1) {
          this.DialogDataList = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    onClose() {
      this.deleteVisible = false;
      this.currentRow = {}
    },
    handleEdit(row) {
      this.$set(row, "edit", true);
    },
    handleDelete(row) {
      this.$confirm(
          "删除设置将影响门店商家报价数据，请谨慎操作。删除前请务必确认已与门店沟通。",
          "说明",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            this.quoteProfileListOper(row, "03", "刪除");
          })
          .catch((err) => {
            console.log(err);
          });
    },
    handleSave(row) {
      if (!this.verifyRow(row)) {
        return;
      }
      this.$confirm(
          "如需修改返利设置，请务必先同门店商家确认。否则将影响商家订单数据统计。",
          "说明",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            this.quoteProfileListOper(
                row,
                row.add ? "01" : "02",
                row.add ? "新增" : "修改"
            );
          })
          .catch((err) => {
            console.log(err);
          });
    },
    quoteProfileListOper(row, type, text) {
      row.startToEndQuote = row.startQuote + "-" + row.endQuote;
      let _row = JSON.parse(JSON.stringify(row));
      delete _row.edit;
      delete _row.add;
      if (_row.androidType === "01") {
        _row.androidAmount = _row.androidAmount === "" ? 0 : _row.androidAmount;
      } else {
        _row.androidRatio = _row.androidRatio === "" ? 0 : _row.androidRatio;
      }

      if (_row.iphoneType === "01") {
        _row.iphoneAmount = _row.iphoneAmount === "" ? 0 : _row.iphoneAmount;
      } else {
        _row.iphoneRatio = _row.iphoneRatio === "" ? 0 : _row.iphoneRatio;
      }
      _api
          .quoteProfileListOper({
            id: row.id,
            merchantCompanyId: row.merchantCompanyId,
            quoteProfitInfo: _row,
            type,
          })
          .then((res) => {
            this.$message.success((res && res.msg) || text + "成功");
            this.handleCurrentChange(this.pageNum);
            this.$set(row, "edit", false);
          })
          .catch((err) => {
            this.handleCurrentChange(this.pageNum);
            this.$message.error((err.data && err.data.msg) || text + "失敗");
          });
    },
    verifyRow(row) {
      let errorMsg = (msg) => {
        this.$message({
          message: msg,
          type: "warning",
        });
      };
      if (row.startQuote === "") {
        errorMsg("请输入最低返利金额");
        return false;
      }
      if (row.endQuote === "") {
        errorMsg("请输入最高返利金额");
        return false;
      }
      if (row.androidType === "02" && row.androidAmount === "") {
        errorMsg("请输入安卓固定返利金额");
        return false;
      }
      if (row.androidType === "01" && row.androidRatio === "") {
        errorMsg("请输入安卓返利比例");
        return false;
      }
      if (row.iphoneType === "02" && row.iphoneAmount === "") {
        errorMsg("请输入苹果固定返利金额");
        return false;
      }
      if (row.iphoneType === "01" && row.iphoneRatio === "") {
        errorMsg("请输入苹果返利比例");
        return false;
      }
      if (
          parseFloat(row.iphoneRatio) > 100 ||
          parseFloat(row.androidRatio) > 100 ||
          parseFloat(row.iphoneRatio) < 0 ||
          parseFloat(row.androidRatio) < 0
      ) {
        errorMsg("返利比例设置不正确");
        return false;
      }
      // if (parseFloat(row.iphoneAmount) > parseFloat(row.endQuote) || parseFloat(row.androidAmount) > parseFloat(row.endQuote) ||
      //     parseFloat(row.iphoneAmount) < 0 || parseFloat(row.androidAmount) < 0) {
      //   errorMsg('请设置0-' + parseFloat(row.endQuote) + '的固定返利金额')
      //   return false
      // }
      if (
          parseFloat(row.iphoneAmount) < 0 ||
          parseFloat(row.androidAmount) < 0
      ) {
        errorMsg("固定返利金额不能小于0");
        return false;
      }
      return true;
    },
    addSetting() {
      let { DialogDataList } = this;
      DialogDataList.push({
        androidAmount: "",
        androidRatio: "",
        androidType: "02",
        companyId: this.companyId,
        endQuote: "",
        id: "",
        iphoneAmount: "",
        iphoneRatio: "",
        iphoneType: "02",
        merchantCompanyId: this.merchantCompanyId,
        merchantId: this.merchantId,
        startQuote: "",
        startToEndQuote: "",
        edit: true,
        add: true,
      });
    },
    // 内部回收
    switchInsideBiz(row) {
      this.insideType = row.isInside
      this.insideTitle = row.isInside ? '启用提示' : '关闭提示'
      this.rowCId = row.companyId;
      this.rowMId = row.merchantId;
      this.insideDialogVisible = true
    },
    // 弹框关闭
    insideClosed() {
      this.insideDialogVisible = false
      this.command = ""
      this.handleCurrentChange(this.pageNum);
    },
    insideSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return
      }
      this.insideBtnLoading = true
      _api.updateMiddleIsInside({
        companyId: this.rowCId,
        merchantId: this.rowMId,
        isOpen: this.insideType,
        command: this.command,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg || "操作成功");
          this.insideDialogVisible = false
        } else {
          this.$message.success(res.msg || "操作成功");
          this.insideDialogVisible = false
        }
        this.insideBtnLoading = false
      }).catch(() => {
        this.insideBtnLoading = false
      });
    },
    // 关闭/开启报价
    offerOperation(row) {
      this.relationRow = row
      this.offerDialogShow = true
    },
    // 设置回收商为指定报价
    setAssign(row) {
      this.relationRow = row
      this.setAssignShow = true
    },
    // 报价操作弹框关闭
    offerDialogClose() {
      this.command = ""
      this.relationRow = {}
      this.procedures = ""
    },
    setAssignDialogClose() {
      this.command = ""
      this.relationRow = {}
    },
    // 报价操作弹框确认按钮
    offerDialogSure() {
      if (this.currentRow.payApplyStatus == '02' && this.relationRow.companyIsEnable == '02' && !this.procedures) {
        this.$message.error('请选择手续费承担方')
        return false
      }
      if (!this.command) {
        this.$message.error('请输入动态口令')
        return false
      }
      let params = {
        command: this.command,
        companyId: this.currentRow.id,
        merchantId: this.relationRow.merchantId,
      };
      this.offerDialogBtnLoading = true
      if (this.relationRow.companyIsEnable == '01') {
        _api.closeQuote(params).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.offerDialogShow = false
            this.handleCurrentChange(this.pageNum);
          }
          this.offerDialogBtnLoading = false
        }).catch(() => {
          this.offerDialogBtnLoading = false
        });
      } else {
        params.processFeeBear = this.procedures
        _api.saveUnionMerchant(params).then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.offerDialogShow = false
            this.handleCurrentChange(this.pageNum);
          }
          this.offerDialogBtnLoading = false
        }).catch(() => {
          this.offerDialogBtnLoading = false
        });
      }
    },
    numberCheckData(prop) {
      var str = this[prop]
      var len1 = str.substr(0, 1);
      var len2 = str.substr(1, 1);
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != ".") {
        str = str.substr(1, 1);
      }
      //第一位不能是.
      if (len1 == ".") {
        str = "";
      }
      //限制只能输入一个小数点
      if (str.indexOf(".") != -1) {
        var str_ = str.substr(str.indexOf(".") + 1);
        if (str_.indexOf(".") != -1) {
          str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
        }
      }
      //正则替换，保留数字和小数点
      str = str.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      if (str > 100) {
        str = 100
      }
      this[prop] = str;
    },
    // 设置服务费
    setServerFee(row) {
      this.merchantId = row.merchantId
      this.serverFeeDialogVisible = true
      this.isShowQuoteChannelServerFee = row.isShowQuoteChannelServerFee ? true : false
      this.quoteChannelServerFee = row.quoteChannelServerFee || ""
    },
    //设置服务费弹框关闭
    serverFeeClosed() {
      this.command = ""
      this.isShowQuoteChannelServerFee = false
      this.quoteChannelServerFee = ""
    },
    //设置服务费弹框确定
    serverFeeSubmit() {
      console.log(this.quoteChannelServerFee);
      if (this.isShowQuoteChannelServerFee && !this.quoteChannelServerFee) {
        this.$message.error('请设置服务费')
        return
      }
      if (this.isShowQuoteChannelServerFee && this.quoteChannelServerFee == 0) {
        this.$message.error('服务费必须大于0')
        return
      }
      if (!this.command) {
        this.$message.error('请输入动态口令')
        return
      }
      let params = {
        companyId: this.clustersofstars,
        merchantId: this.merchantId,
        isShowQuoteChannelServerFee: this.isShowQuoteChannelServerFee,
        quoteChannelServerFee: this.quoteChannelServerFee,
        command: this.command,
      }
      this.serverFeeBtnLoading = true
      _api.setQuoteChannelServerFee(params).then(res => {
        this.$message.success(res.msg || "操作成功");
        this.serverFeeDialogVisible = false
        this.serverFeeBtnLoading = false
        this.handleCurrentChange(this.pageNum);
      }).catch(() => {
        this.serverFeeBtnLoading = false
      })
    },
    // 设置收货方
    setReceiptType(row) {
      // 回收商id
      this.merchantId = row.merchantId;
      // 收货地址类型
      this.receiptType = row.receiptType;

      // 查看地址
      if (!this.currentRow.isOut) {
        // 修改
        this.distributeShow = true;
      } else {
        this.receiptTypeDialogVisible = true;
      }
      this.queryAddress()
    },
    // 设置收货方弹框关闭
    receiptTypeClosed() {
      this.command = ""
    },
    //设置收货方弹框确定
    receiptTypeSubmit() {
      if (!this.command) {
        this.$message.error('请输入动态口令')
        return
      }
      let params = {
        companyId: this.clustersofstars,
        merchantId: this.merchantId,
        receiptType: this.receiptType,
        command: this.command,
      }
      this.receiptTypeBtnLoading = true
      _api.updateReceiptType(params).then(res => {
        this.$message.success(res.msg || "操作成功");
        this.receiptTypeDialogVisible = false
        this.receiptTypeBtnLoading = false
        this.handleCurrentChange(this.pageNum);
      }).catch(() => {
        this.receiptTypeBtnLoading = false
      })
    },
    //设置指定报价
    assignSubmit() {
      if (!this.command) {
        this.$message.error('请输入动态口令')
        return
      }
      let params = {
        companyId: this.currentRow.id,
        isAssign: !this.relationRow.isAssign,
        merchantId: this.relationRow.merchantId,
        command: this.command,
      }
      this.assignBtnLoading = true
      _api.setAssign(params).then(res => {
        this.$message.success(res.msg || "操作成功");
        this.setAssignShow = false
        this.assignBtnLoading = false
        this.handleCurrentChange(this.pageNum);
      }).catch(() => {
        this.assignBtnLoading = false
      })
    },
    queryAddress() {
      let params = {
        companyId: this.clustersofstars,
        merchantId: this.merchantId,
        receiptType: this.receiptType,
      }

      _api.queryAddress(params).then(res => {
        if (res.code == 1) {
          this.addressObj = res.data
        }
      })
    },
    receiptTypeChange(e) {
      this.queryAddress()
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.SystemOffer-list {
  .enable-tip {
    color: #FF8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}

.set_top_ck {
  span {
    font-size: 15px;
    font-weight: bold;
    margin-right: 10px;
  }
}

.categorySet {
  height: 82px;
  background: #FFFFFF;
  border: 1px solid #E4ECFD;
  border-radius: 10px;
  margin: 20px 0;

  .cs_center {
    padding: 0 25px;
    height: 34px;
    background: #EEF3FE;
    border: 1px solid #E4ECFD;
    border-radius: 10px 10px 0px 0px;
  }
}

.dialog-btns {
  display: flex;
  justify-content: space-around;
}

.dialog-btn {
  cursor: pointer;
  user-select: none;
}

.dialog-btn.edit,
.dialog-btn.save {
  color: #0033cc;
}

.dialog-btn.delete {
  color: #ff3300;
}

/deep/ .el-input-group__append {
  border: 1px solid #0981ff;
  background: #0981ff;
  color: #ffffff;
}

.address-box {
  background-color: #f5f6fa;
  color: #666;
  width: 400px;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
}

.mb-10 {
  margin-bottom: 10px;
}

.dis_flex {
  display: flex;
}

.al_c {
  align-items: center;
}

.ju_sb {
  justify-content: space-between;
}
</style>
