<template>
  <div class="GlobalTable">
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="this.seachDataList"
                 :currentPage="page.pageNum" :total="this.page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="商家名称" slot="companyName" align="center">
        <template slot-scope="{ row }">
          <div class="ycyc2">
            <span v-if="row.channel == '01'" class="channeltype1">后台</span>
            <span v-if="row.channel == '02'" class="channeltype2">前端</span>
            <el-tooltip effect="dark" :content="row.companyName" placement="top">
              <p style="
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              ">
                {{ row.companyName || "--" }}
              </p>
              <!-- <span>{{ row.companyName || "--" }}</span> -->
            </el-tooltip>
          </div>
          <!--          -->
        </template>
      </el-table-column>
      <el-table-column label="省市区" slot="provinceCityDistrict" align="center">
        <template slot-scope="{ row }">
          <el-tooltip :content="row.province + row.city + row.region" placement="top">
            <p style="
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              ">
              {{ row.province + row.city + row.region }}
            </p>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column label="所属运营商" slot="operator" align="center">
        <template slot-scope="{ row }">
          <el-tooltip :content="ownedOperator(row.operator)" placement="top">
            <p style="
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              ">
              {{ ownedOperator(row.operator) }}
            </p>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column label="门店数量" slot="stores" align="center">
        <template slot-scope="{ row }">
          <div @click="storeDialog(row, '01')" class="tableBtn" v-if="row.stores">
            <span style="border-bottom: 1px solid #0981ff">{{
                row.stores
              }}</span>
          </div>
          <span v-else>{{ row.stores }}</span>
        </template>
      </el-table-column>
      <el-table-column label="员工人数" slot="staffs" align="center">
        <template slot-scope="{ row }">
          <div @click="staffDialog(row, 'company')" class="tableBtn" v-if="row.staffs">
            <span style="border-bottom: 1px solid #0981ff">{{
                row.staffs
              }}</span>
          </div>
          <span v-else>0</span>
        </template>
      </el-table-column>
      <el-table-column label="累计交易（笔）" slot="recycles" align="center">
        <template slot-scope="{ row }">
          <div @click="toLink(row)" class="tableBtn" v-if="row.recycles">
            <span style="border-bottom: 1px solid #0981ff">{{
                row.recycles
              }}</span>
          </div>
          <span v-else>0</span>
        </template>
      </el-table-column>

      <el-table-column label="商家地址" slot="operationAddress" align="center">
        <template slot-scope="{ row }">
          <el-tooltip :content="row.address" placement="top">
            <p style="
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                      ">
              {{ row.address }}
            </p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="注册渠道" slot="operationAddress" align="center">
        <template slot-scope="{ row }">
          <el-tooltip :content="row.address" placement="top">
            <p v-if="row.channel === '01'">
              后台
            </p>
            <p v-else-if="row.channel === '02'">
              小程序
            </p>
            <p v-else>--</p>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column label="合作回收商" slot="operation" align="center">
        <template slot-scope="{ row }">
          <div @click="handleDialog(row)" v-if="row.merchants" class="tableBtn">
            <span style="border-bottom: 1px solid #0981ff">{{
                row.merchants
              }}</span>
          </div>
          <span v-else>0</span>
<!--          <div class="tableBtn" style="-->
<!--                      font-size: 12px;-->
<!--                      font-family: FZLanTingHeiS-R-GB;-->
<!--                      font-weight: 400;-->
<!--                      color: #0981ff;-->
<!--                    " @click="Addpartner(row)">-->
<!--            添加-->
<!--          </div>-->
        </template>
      </el-table-column>

<!--      <el-table-column label="切换报价矩阵" slot="operation" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <div v-if="row.isMatrixCompany">-->
<!--            &#45;&#45;-->
<!--          </div>-->
<!--          <div v-else>-->
<!--            <a class="fc_line" v-if="row.matrixName" target="_blank"-->
<!--               :href="`/matrix/config?matrixId=${row.matrixId}&matrixName=${row.matrixName}`">-->
<!--              {{ row.matrixName }}-->
<!--            </a>-->
<!--            <div v-else class="f_line">&#45;&#45;</div>-->
<!--            <div style="color: #F19C37;cursor: pointer" @click="switerMatrix(row)">切换</div>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->

      <el-table-column label="启用门店后台" slot="operation" align="center">
        <template slot-scope="{ row }">
          <p v-if="row.isOpenPc" class="lv_fc">已开启</p>
          <p v-else class="lv_fc_red">已关闭</p>
<!--          <el-switch inactive-color="#bebebe" @change="handleSwitch(row)" v-model="row.isOpenPc"></el-switch>-->
          <div class="tableBtn" v-if="row.isAssignAccount" @click="branchAcc(row)"><span style="border-bottom: 1px solid #0981ff">查看账号</span></div>
          <div v-else>暂无账号</div>
        </template>
      </el-table-column>
      <!-- 备注 -->
<!--      <el-table-column label="利润模式" slot="operation" align="center">-->
<!--        &lt;!&ndash; slot-scope="{ row }" &ndash;&gt;-->
<!--        <template slot-scope="{ row }">-->
<!--          <div>{{ row.profitMode == "after" ? "后返利模式" : "差价模式" }}</div>-->
<!--          <div class="tableBtn" @click="editProfitMode(row)">-->
<!--            <span style="border-bottom: 1px solid #0981ff">{{-->
<!--                row.payMode == "online" ? "线上支付" : "修改"-->
<!--              }}</span>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="启用内部回收" slot="operation" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <el-switch inactive-color="#bebebe" @change="switchInsideBiz(row)" v-model="row.isOpenInsideBiz"></el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="正式启用" slot="operation" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <el-switch inactive-color="#bebebe" @change="handleSwitchUsed(row)" v-model="row.isSymbolUsed"></el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="启用预估价下单模式" slot="operation" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <el-switch inactive-color="#bebebe" @change="hpreEstimateSwitchUsed(row)"-->
<!--                     v-model="row.useEstimatedMode"></el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column label="靓机回收" slot="operation" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <el-switch inactive-color="#bebebe" @change="BeautifulSwitch(row)" v-model="row.isOpenNiceBiz"></el-switch>-->
<!--          <div @click="editClickT(row)" style="cursor: pointer;color: #409EFF" v-if="!row.isOpenNiceBiz">-->
<!--            <u>编辑</u>-->
<!--          </div>-->
<!--          <div @click="editClickT(row)" style="cursor: pointer;color: #409EFF" v-else>-->
<!--            <u>查看</u>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      &lt;!&ndash; 备注 &ndash;&gt;-->
<!--      <el-table-column label="备注说明" width="70rpx" slot="operation" align="center">-->
<!--        &lt;!&ndash; slot-scope="{ row }" &ndash;&gt;-->
<!--        <template slot-scope="{ row }">-->
<!--          <el-tooltip class="item" effect="dark" :content="row.masterNote" placement="top">-->
<!--            <div class="Remarks">-->
<!--              {{ row.masterNote }}-->
<!--            </div>-->
<!--          </el-tooltip>-->
<!--          <div class="tableBtn" @click="setRemark(row)">{{ row.masterNote ? '修改' : '添加' }}</div>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="是否标记矩阵商户" slot="operation" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <span v-if="row.isMatrixCompany" style="color: #FF0000">是</span>-->
<!--          <span v-else>否</span>-->
<!--        </template>-->
<!--      </el-table-column>-->


<!--      <el-table-column fixed="right" width="130" label="操作" slot="operation" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <el-dropdown @command="(e) => { handleOperation(e, row) }">-->
<!--            <el-button size="small" type="primary">-->
<!--              更多操作<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
<!--            </el-button>-->
<!--            <el-dropdown-menu slot="dropdown">-->
<!--              <el-dropdown-item command="isenable">{{ row.isEnable ? '禁用' : '启用' }}</el-dropdown-item>-->
<!--              <el-dropdown-item command="invitation">邀请商家设置</el-dropdown-item>-->
<!--              &lt;!&ndash;              <el-dropdown-item command="out">{{ row.isOut ? '禁用' : '启用' }}外部商户</el-dropdown-item>&ndash;&gt;-->
<!--              <el-dropdown-item command="img">查看营业执照</el-dropdown-item>-->
<!--              <el-dropdown-item v-if="!row.isMatrixCompany" command="associationStore">标记矩阵商户</el-dropdown-item>-->
<!--              <el-dropdown-item command="storeurl">获取门店应用</el-dropdown-item>-->
<!--              <el-dropdown-item command="hidePrice">隐藏报价信息</el-dropdown-item>-->
<!--              <el-dropdown-item command="pricesdown">压价设置</el-dropdown-item>-->
<!--              <el-dropdown-item command="editinformation">编辑信息</el-dropdown-item>-->
<!--            </el-dropdown-menu>-->
<!--          </el-dropdown>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </GlobalTable>
    <DialogTbale @onClose="onClose" :loading="storeLoading" :show="deleteVisible" title="门店列表" :columns="storesColumns"
                 :data="dialogStoreList" :currentPage="storeParams.pageNum" :total="storeParams.total" :pageSize="10" :width="1000"
                 @handleCurrentChange="getStoreList">
      <el-table-column label="所属分区" slot="areaName" class="store-name" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.areaName || "--" }}</span>
        </template>
      </el-table-column>

      <el-table-column label="门店名称" slot="storeName" class="store-name" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.storeName }}</span>
          <el-tag v-if="!row.isEnable" size="mini" type="danger" effect="dark" style="margin-left: 5px">
            已禁用
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="门店地址" slot="storeAddress" class="store-name" align="center">
        <template slot-scope="{ row }">
          <el-tooltip :content="row.storeAddress" placement="top">
            <p style="
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        overflow: hidden;
                      ">
              {{ row.storeAddress }}
            </p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="店长姓名" slot="storeManager" class="store-name" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.storeManager || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店长联系方式" slot="storeManagerPhone" class="store-name" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.storeManagerPhone || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店员数量" slot="staffCount" align="center">
        <template slot-scope="{ row }">
          <div @click="staffDialog(row, 'store')" class="tableBtn" v-if="row.staffCount && storeDialogType == '01'">
            <span style="border-bottom: 1px solid #0981ff">{{
                row.staffCount
              }}</span>
          </div>
          <div v-else>{{ row.staffCount }}</div>
        </template>
      </el-table-column>
    </DialogTbale>
    <DialogTbale @onClose="staffClose" :loading="staffLoading" :show="staffVisible" title="店员列表" :columns="staffColumns"
                 :data="dialogStaffList" :currentPage="staffParams.pageNum" :total="staffParams.total" :pageSize="10"
                 :width="staffListType == 'company' ? 1500 : 600" @handleCurrentChange="getStaffList">
      <div class="flex staff-list-form" style="margin-bottom: 20px" v-if="staffListType == 'company'">
        <div>
          <span style="width: 93px">门店名称：</span>
          <el-select v-model="staffParams.storeId" filterable size="small" clearable placeholder="请选择或输入门店名称">
            <el-option v-for="item in companyList" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </div>
        <div class="flex" style="margin-left: 10px">
          <span style="width: 56px">手机号：</span>
          <el-input v-model="staffMobile" oninput="this.value=value.replace(/[^0-9]/g,'')" size="small" clearable
                    placeholder="请输入手机号"></el-input>
        </div>
        <div class="flex" style="margin-left: 10px">
          <span style="width: 42px">姓名：</span>
          <el-input v-model="staffName" size="small" clearable placeholder="请输入姓名"></el-input>
        </div>
        <div>
          <span style="margin-left: 10px">是否关注公众号：</span>
          <el-select v-model="isSubscribe" size="small" clearable placeholder="请选择是否关注公众号">
            <el-option label="是" value="01"></el-option>
            <el-option label="否" value="02"></el-option>
          </el-select>
        </div>
        <el-button type="primary" style="margin-left: 10px" size="small" @click="searchSetting">
          查询
        </el-button>
        <el-button size="small" :loading="isExport" type="success" icon="el-icon-download"
                   @click="clickExcel">导出Excel</el-button>
      </div>
      <!-- <div style="background:white;text-align:right;margin-bottom: 20px">
      </div> -->
      <el-table-column label="姓名" slot="staffName" align="center">
        <template slot-scope="{ row }">
          <div class="flex">
            <el-tag size="mini" :key="row.isMajor
              ? '创建人'
              : row.staffType == '01'
                ? '子账号'
                : row.staffType == '02'
                  ? '总监'
                  : row.staffType == '03'
                    ? '店长'
                    : '店员'
              " :type="row.isMajor
    ? 'danger'
    : row.staffType == '01'
      ? 'primary'
      : row.staffType == '02'
        ? 'warning'
        : row.staffType == '03'
          ? 'warning'
          : 'success'
    " effect="dark">
              {{
                row.isMajor
                    ? "创建人"
                    : row.staffType == "01"
                        ? "子账号"
                        : row.staffType == "02"
                            ? "总监"
                            : row.staffType == "03"
                                ? "店长"
                                : "店员"
              }}
            </el-tag>
            <span style="margin-left: 5px">{{
                row.staffName || row.nickName
              }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="所属/管理门店" slot="storeName" align="center">
        <template slot-scope="{ row }">
          <div @click="storeDialog(row, '02')" class="tableBtn" v-if="row.staffType == '02'">
            <span style="border-bottom: 1px solid #0981ff">{{
                row.storeNum
              }}</span>
          </div>
          <span v-else>{{ row.storeName || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最近登录时间" slot="lastLoginTime" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.lastLoginTime || "--" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最近下单时间" slot="lastCreateOrderTime" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.lastCreateOrderTime">{{
              row.lastCreateOrderTime
            }}</span>
          <span v-else>无下单记录</span>
        </template>
      </el-table-column>
      <el-table-column label="是否关注公众号" slot="isSubscribe" align="center">
        <template slot-scope="{ row }">
          <span :style="row.isSubscribe == '01' ? 'color:green;' : 'color:red;'">{{
              row.isSubscribe == "01" ? "是" : "否"
            }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="操作" slot="operation"  width="250px" align="center">-->
<!--        <template slot-scope="{ row }">-->
<!--          <el-button type="danger" size="small" @click="forciblyLoginOutShow(row.staffId)" round>强制退登</el-button>-->
<!--          <el-button type="warning" size="small" round @click="clearWechatmessages(row.staffId)">清除微信信息</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </DialogTbale>
    <el-dialog class="acc_dialog" :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false"
               width="500px" @closed="handleDialogClosed">
      <div style="font-size: 14px; color: #ff687b">{{ tipTxt }}</div>
      <div v-if="dialogTitle == '提示'" style="
                  font-size: 16px;
                  color: #666666;
                  height: 100px;
                  line-height: 50px;
                ">
        <div>是否确认{{ tipOpenPc }}该商家的门店后台管理系统？</div>
        <div class="command">
          <div>动态口令：</div>
          <el-input v-model="formOpenPc.command" placeholder="请输入谷歌动态口令"
                    oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
        </div>
      </div>

      <el-form ref="form" :model="account" v-if="dialogTitle == '分配账号' || dialogTitle == '查看账号'" style="margin-top: 25px">
        <el-form-item label="账号">
          <el-input v-model="account.acc" placeholder="请输入门店账号" :disabled="dialogTitle == '分配账号' ? false : true"
                    @blur="account.acc = $event.target.value"
                    oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
          <el-button type="primary" size="small" style="margin-left: 20px" v-if="dialogTitle == '分配账号'"
                     @click="createPcAcc">自动生成账号
          </el-button>
        </el-form-item>
<!--        <el-form-item label="密码">-->
<!--          <el-input :type="type" disabled placeholder="请输入门店密码" onfocus="this.type='password'" v-model="account.pwd"-->
<!--                    autocomplete="off">-->
<!--            <i v-if="dialogTitle != '查看账号' || isReset" slot="suffix" class="pwd-style" :class="elIcon" autocomplete="auto"-->
<!--               @click="pwdFlag = !pwdFlag" />-->
<!--          </el-input>-->
<!--          <el-button type="primary" v-if="dialogTitle == '查看账号'" size="small" style="margin-left: 20px"-->
<!--                     @click="reset">重置密码-->
<!--          </el-button>-->
<!--        </el-form-item>-->
        <p>初始密码默认为创建人登录手机号</p>
        <p style="margin-top: 10px;font-size: 16px">繁星门店管理后台地址：<span style="cursor: pointer" @click="openAction('http://ka.btstars.cn',true)">http://ka.btstars.cn</span></p>
<!--        <el-form-item label="" label-width="40px">-->
<!--          <span v-if="dialogTitle == '分配账号'">初始密码默认为创建人手机号码，不可修改</span>-->
<!--          <span v-else>重置后密码为初始密码，为创建人手机号码</span>-->
<!--        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleClosed">取消</el-button>
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="usedDialogVisible" :close-on-click-modal="false" width="500px"
               @closed="handleDialogClosed">
      <div style="
                  font-size: 16px;
                  color: #666666;
                  line-height: 50px;
                ">
        <div style="text-align: center;" v-if="usedType">是否确认该商家已启用？</div>
        <div style="text-align: center;" v-else>是否确认标记该商家为未启用商家？</div>
        <div class="command" v-if="!usedType">
          <div>动态口令：</div>
          <el-input v-model="usedCommand" placeholder="请输入谷歌动态口令"
                    oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="handleClosed">取消</el-button>
        <el-button type="primary" @click.native="usedSuccess">确定</el-button>
      </span>
    </el-dialog>
    <!-- 启用预估价下单模式 -->
    <el-dialog title="提示" :visible.sync="estimateVisible" :close-on-click-modal="false" width="500px"
               @closed="estimateClosed">
      <div style="
                  font-size: 16px;
                  color: #666666;
                  line-height: 50px;
                ">
        <div style="text-align: center;" v-if="emType">是否确认启用预估价下单模式？</div>
        <div style="text-align: center;" v-else>是否确认禁用预估价下单模式？</div>
        <div class="command">
          <div>动态口令：</div>
          <el-input v-model="emCommand" placeholder="请输入谷歌动态口令"
                    oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="estimateClosed">取消</el-button>
        <el-button type="primary" @click.native="estimateSuccess">确定</el-button>
      </span>
    </el-dialog>
    <el-image-viewer v-if="showViewer" :on-close="() => {
      showViewer = false;
    }
      " :url-list="imgList" />

    <!-- //良机回收开关开启查看 -->
    <el-dialog class="acc_dialog" :title="openorclose" :visible.sync="BeautifulShow" :close-on-click-modal="false"
               width="500px" @closed="handleDialogClosed">
      <div style="font-size: 14px; color: rgb(255, 104, 123); margin: 0 0 0 0">
        {{
          openorclose == "开启提示"
              ? "开启后该商家可使用设置的登录手机号登录靓机回收商账号。"
              : "关闭后该商家的所属的靓机回收商账号无法登录。"
        }}
      </div>

      <div style="
                  font-size: 16px;
                  color: #666666;
                  height: 100px;
                  line-height: 50px;
                ">
        <div style="font-weight: 500">
          是否确认{{ openorclose == "开启提示" ? "开启?" : "关闭?" }}
        </div>
        <div class="command">
          <div>动态口令：</div>

          <el-input v-model="formOpenPc.command" placeholder="请输入谷歌动态口令"
                    oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="BeautifulClosed">取消</el-button>
        <el-button type="primary" @click.native="BeautifulSuccess">确定</el-button>
      </span>
    </el-dialog>
    <!-- 压价设置弹窗 -->
    <el-dialog title="压价设置" :visible.sync="addPriceShow" :close-on-click-modal="false" width="800px">
      <div style="margin-bottom: 15px;">
        <span>设置方式：</span>
        <el-radio-group v-model="pattern">
          <el-radio :label="1">公司统一设置</el-radio>
          <el-radio :label="2">门店店长设置</el-radio>
        </el-radio-group>
      </div>
      <div style="margin-bottom: 10px;" v-if="pattern == 1">
        <span>门店是否可查看显示比例：</span>
        <el-radio-group v-model="proportion">
          <el-radio :label="1">可查看</el-radio>
          <el-radio :label="2">不可查看</el-radio>
        </el-radio-group>
      </div>
      <div style="margin-bottom: 15px;">
        <div class="biliStyle">
          <span>设置显示比例：</span>
          <el-button v-if="pattern == 1" size="mini" @click="editConfig()" icon="el-icon-plus" type="primary" round>添加
          </el-button>
        </div>
        <span v-if="pattern == 1" style="color: #ff687b;font-size: 14px;"><i
            class="el-icon-warning-outline"></i>设置后按最高门店显示价（差价模式显示价即报价，后返利模式为报价扣除利润红包）所在的区间，计算显示比例和压价金额，若压价有上限则按上限限制金额。</span>
        <span v-else style="color: #ff687b;font-size: 14px;"><i
            class="el-icon-warning-outline"></i>按门店设置显示比例需通过小程序设置</span>
      </div>
      <div v-if="pattern == 1">
        <GlobalTable class="mt-20" style='padding-bottom:20px;' ref="GlobalTable" :columns="regionColumns"
                     :data="regionList" :maxHeight="500" :isPagination="false">
          <el-table-column label="最高门店显示价区间" width="135px" slot="rank" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.startQuote }}≤区间＜{{ row.endQuote }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="显示比例" slot="hideQuoteRatio" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.quoteRatio }}%</span>
            </template>
          </el-table-column> -->
          <el-table-column label="压价隐藏比例" slot="markUpLimit" align="center">
            <template slot-scope="{ row }">
              <span style="color:#0981FF">{{ row.hideQuoteRatio }}%</span>
            </template>
          </el-table-column>
          <el-table-column label="自动压价上限" slot="hideQuoteLimit" align="center">
            <template slot-scope="{ row }">
              {{ row.hideQuoteLimit }}
            </template>
          </el-table-column>
          <el-table-column label="操作" slot="operation" align="center">
            <template slot-scope="{ row }">
              <el-button type="primary" size="mini" @click="editConfig(row)">编辑</el-button>
              <el-button type="danger" size="mini" @click="deletConfig(row)">删除</el-button>
            </template>
          </el-table-column>
        </GlobalTable>
      </div>
      <div>
        <span style="margin-right: 40px;"> 最近修改人：{{
            configAlldata.quoteConfigLastName
          }}-{{ configAlldata.quoteConfigLastAcc }}</span>
        <span>{{ configAlldata.quoteConfigLastTime }}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="addPriceShow = false">取消</el-button>
        <el-button type="primary" @click.native="submitAddPrice()">确定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑压价 -->
    <el-dialog title='编辑加价规则' :visible.sync="editPriceShow" :close-on-click-modal="false" width="500px"
               @closed="editPriceClosed">
      <el-form :model="editPriceForm" :rules="editPricerules" ref="editPriceRulesForm" label-width="83px">
        <el-form-item label="最高门店显示价区间:" label-width="145px">
          <el-col :span="11">
            <el-form-item>
              <el-input size="small" placeholder="最低显示价" v-model.trim="editPriceForm.startQuote"></el-input>
            </el-form-item>
          </el-col>
          <el-col style="text-align: center" :span="2">~</el-col>
          <el-col :span="11">
            <el-form-item>
              <el-input size="small" maxlength="5" placeholder="最高显示价" v-model.trim="editPriceForm.endQuote"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="压价隐藏比例:" prop="hideQuoteRatio" label-width="110px">
          <el-input size="small" min='0' max='100' placeholder="输入压价隐藏比例" v-model.trim="editPriceForm.hideQuoteRatio"
                    @input="limitInput($event, 'hideQuoteRatio')" @blur="inputBlur('hideQuoteRatio')">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="压价上限:" prop="hideQuoteLimit">
          <el-input size="small" placeholder="输入压价上限" maxlength="5" v-model.trim="editPriceForm.hideQuoteLimit"
                    @input="hideQuoteLimitChange">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="editPriceShow = false">取消</el-button>
        <el-button type="primary" @click.native="submitEditPrice()">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog class="nice_dialog" :title="isOpenNiceBizD ? '查看靓机回收商信息' : '编辑靓机回收商信息'" :visible.sync="BianjiText"
               :close-on-click-modal="false" width="700px" @closed="handleDialogClosed">
      <div class="shuoming" style="font-size: 14px; color: rgb(255, 104, 123); margin: 0 0 20px 0">
        说明：靓机回收商添加后可直接进入小程序，无须上传营业执照
      </div>

      <el-form ref="form" style="hight: 50rpx" label-width="106px">
        <el-form-item label="回收商家名称" :required="true">
          <el-input maxlength="15" style="width: 100%" v-model="merchantName" :disabled="isOpenNiceBizD">
          </el-input>
        </el-form-item>
        <!-- isOpenNiceBiz -->
        <el-form-item label="联系人" :required="true">
          <el-col :span="11">
            <el-input maxlength="10" style="width: 70%" v-model="contactName" :disabled="isOpenNiceBizD"></el-input>
          </el-col>
          <el-col class="line" :span="3">联系电话</el-col>
          <el-col :span="10">
            <el-input maxlength="11" v-model="contactPhone" :disabled="isOpenNiceBizD"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="省市区" class="shq" :style="isOpenNiceBizD ? 'pointer-events: none;' : ''" :required="true">
          <Provinces @areaID="areaChild" @provinceID="provinceChild" @cityID="cityChild" :addressInfo="addressInfo"
                     :optionsProvince="optionsProvince" :optionsArea="optionsArea" :optionsCity="optionsCity" />
        </el-form-item>
        <el-form-item label="详细地址" :required="true">
          <el-input maxlength="50" style="width: 100%" v-model="merchantAddress" :disabled="isOpenNiceBizD">
          </el-input>
        </el-form-item>

        <el-form-item label="营业执照" :style="isOpenNiceBizD ? 'pointer-events: none;' : ''" :required="true">
          <ElImgUpload key="dialogImageUrl" :exist-image="dialogImageUrl" @handleDelete="handleDelete('dialogImageUrl')"
                       @handleChange="handleChange($event)" />
        </el-form-item>

        <el-form-item label="登录手机号" :required="true">
          <el-col>
            <el-input maxlength="11" style="width: 100%" v-model="loginMobile" :disabled="loginMobilebool || (!loginMobilebool && isOpenNiceBizD)
              ? true
              : false
              "></el-input>
          </el-col>
        </el-form-item>
        <!-- 门店发货设置 -->
        <div class="storeSendSet">
          <div class="store-lable">
            <div class="fw-500"><span style="color:#FF687B">* </span>门店发货设置</div>
            <div class="remark">说明：门店发货设置发往靓机商家时，不可启用分转</div>
          </div>
          <div>
            <el-radio v-model="storeSendSet" label="00">发往靓机回收商</el-radio>
            <el-radio v-model="storeSendSet" label="01">发往成交回收商</el-radio>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="liangjiclose">取消</el-button>
        <el-button type="primary" @click.native="liangjiadd()">确定</el-button>
      </span>
    </el-dialog>

    <!-- 备注弹出框 -->
    <el-dialog class="acc_dialog" :title="RemarksTitle" :visible.sync="RemarksShow" :close-on-click-modal="false"
               width="500px" @closed="handleDialogClosed">
      <div style="margin-bottom: 20px">备注内容</div>
      <el-input maxlength="50" type="textarea" :rows="4" placeholder="请输入备注内容,最多50字" v-model="RemarkslogTxt">
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="Remarks_log_cl">取消</el-button>
        <el-button type="primary" @click.native="Remarks_log_sure">确定</el-button>
      </span>
    </el-dialog>

    <!-- 启用禁用提示框 -->
    <el-dialog class="acc_dialog" :title="isEnable ? '启用提示' : '禁用提示'" :visible.sync="DisableShow"
               :close-on-click-modal="false" width="500px" @closed="handleDialogClosed">
      <div style="font-size: 14px; color: rgb(255, 104, 123); margin: 0 0 10px 0" v-if="!isEnable">
        关闭后该商家的所属的账号均无法登录。
      </div>
      <div style="margin-bottom: 20px; font-weight: 500">
        {{ isEnable ? "是否确认启用？" : "是否确认关闭？" }}
      </div>
      <div class="command" style="margin-bottom: 20px" v-if="!isEnable">
        <div style="margin-right: 10px">禁用原因:</div>
        <el-input style="width: 368px" maxlength="20" type="textarea" :rows="3" placeholder="请填写禁用原因告知用户(限20字)"
                  v-model="disableNote">
        </el-input>
      </div>
      <div class="command">
        <div>动态口令：</div>

        <el-input v-model="formOpenPc.command" placeholder="请输入谷歌动态口令"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="Disable_log_cl">取消</el-button>
        <el-button type="primary" @click.native="Disable_log_sure">确定</el-button>
      </span>
    </el-dialog>
    <!-- 压价设置提示框 -->
    <el-dialog class="acc_dialog" title="提示" :visible.sync="yajiaShow" :close-on-click-modal="false" width="500px"
               @closed="yajiaShow = false">
      <div style="margin-bottom: 20px; font-weight: 500 ;text-align: center;font-size: 18px;">
        是否确认修改压价设置？
      </div>
      <div class="command">
        <div>动态口令：</div>
        <el-input v-model="yaCommand" placeholder="请输入谷歌动态口令"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="yajiaShow = false">取消</el-button>
        <el-button type="primary" @click.native="yaPricesure">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogPayModeVisible" :close-on-click-modal="false" width="380px"
               @closed="payModeDialogClosed">
      <div style="color: #333333; font-size: 14px">
        该商户已启用线上支付，如需修改利润模式请前往【支付管理-线上支付申请】进行修改配置参数
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="payModeDialogClosed">取消</el-button>
        <el-button type="primary" @click.native="handlePayMode">立即前往</el-button>
      </span>
    </el-dialog>
    <!-- 门店应用地址弹出框 -->
    <el-dialog class="store_url_dialog" title="获取门店应用地址" :visible.sync="storeUrlShow" :close-on-click-modal="false"
               width="500px" @closed="storeUrlClosed">
      <div style="margin-bottom: 10px">门店应用URL地址：</div>
      <div>{{ storeUrl }}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="storeUrlClosed">取消</el-button>
        <el-button type="primary" @click.native="copyStoreUrl">复制地址</el-button>
      </span>
    </el-dialog>

    <el-dialog title="添加合作商" :visible.sync="Addpartnershow" :close-on-click-modal="false" width="800px"
               @closed="Addpartnerclose">
      <div class="addparto" style="display: flex;flex-direction: row;align-items: center;">
        <img style="width:14px;height:14px;margin-right:5px;" src="../../assets/images/ico_ts_red (2).png">
        说明:输入回收商名称进行模糊查询，选中回收商后点击确认完成添加。
      </div>
      <el-form :inline="true" class="demo-form-inline" size="small" style="margin-top: 13px">
        <el-form-item label="回收商名称">
          <el-input v-model="username" placeholder="请输入回收商名称查询"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>

      <!-- <el-table
        :data="tableData"
        ref="singleTable"
        highlight-current-row
        border
        height="300px"
        style="width: 100%; margin-bottom: 20px"
      >
        <el-table-column label="单选" width="50" align="center">
          <template scope="scope">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                :label="scope.row.merchantId"
                @change="checkBoxChange(scope.row.merchantId)"
                >1</el-checkbox
              >
            </el-checkbox-group>
          </template>
        </el-table-column>
        <el-table-column
          prop="merchantName"
          label="回收商名称"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="contactName"
          label="联系人"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="contactPhone" label="电话" align="center">
        </el-table-column>
        <el-table-column prop="fullAddress" label="地址" align="center">
        </el-table-column>
        <el-table-column prop="entryTime" label="入驻时间" align="center">
        </el-table-column>
      </el-table> -->


      <GlobalTable ref="GlobalTable" :columns="tableColumns2" :data="this.tableData" :currentPage="page2.pageNum"
                   :total="page2.total" :pageSize='5' :isIndex="false" @handleCurrentChange="handleCurrentChange2">

        <el-table-column label="单选" width="50" slot="rio" align="center">
          <template scope="scope">
            <el-checkbox-group v-model="checkList">
              <el-checkbox :label="scope.row.merchantId"
                           @change="checkBoxChange(scope.row.merchantId, scope.row)"></el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>
      </GlobalTable>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="username = '';
        Addpartnershow = false;
        checkList = [];
        ">取消</el-button>
        <el-button type="primary" @click.native="Addpartitle">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="添加确认" :visible.sync="Google" :close-on-click-modal="false" width="480px">
      <div class="addparto" style="display: flex;flex-direction: row;align-items: center;">
        {{
          rowdata.payApplyStatus != '02' ? '确认前请与连锁门店业务负责人核实确认' : '提示：请与连锁门店业务负责人核实确认手续费承担方'
        }}
      </div>
      <div style="margin-bottom: 20px" v-if="rowdata.payApplyStatus != '02'"></div>
      <el-radio-group v-model="radiostore" style="margin-top: 18px;margin-bottom: 22px"
                      v-if="rowdata.payApplyStatus == '02'">
        <el-radio label="01">门店承担手续费</el-radio>
        <el-radio label="02">回收商承担手续费</el-radio>
      </el-radio-group>
      <div v-if="effectiveStatus == '01'" style="display: flex;align-items: center; margin-bottom: 22px">
        已有分转关系（分转生效中）
      </div>
      <div v-else-if="effectiveStatus == '00'" style="display: flex;align-items: center; margin-bottom: 22px">
        已有分转关系（分转未生效）
      </div>
      <div v-else-if="effectiveStatus == '02'" style="display: flex;align-items: center; margin-bottom: 22px">
        已有分转关系（分转已禁用）
      </div>
      <div v-else style="display: flex;align-items: center; margin-bottom: 22px">
        <span style="margin-right: 20px;transform: translateY(-3px)">是否启用分转到繁星仓库(立即生效)</span>
        <el-radio-group v-model="isDivide">
          <el-radio label="01">是</el-radio>
          <el-radio label="02">否</el-radio>
        </el-radio-group>
      </div>
      <div class="command">
        <div style="width: 85px;">谷歌验证码：</div>
        <el-input v-model="Googleid" placeholder="输入谷歌动态验证码"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="
          Google = false;
        Googleid = '';
        radiostore = '';
        ">取消</el-button>
        <el-button type="primary" @click.native="AddparcheckFn">确定</el-button>
      </span>
    </el-dialog>
    <!-- 强退动态口令弹出框 -->
    <el-dialog title='提示' :visible.sync="commandShow" :close-on-click-modal="false" width="500px" @closed="commandClosed">
      <div class="tip-sure">是否确认清空并强制退出该账号小程序登录信息？</div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="loginOutCommand" placeholder="请输入谷歌动态口令"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="forciblyLoginOut">确定</el-button>
      </span>
    </el-dialog>
    <!-- 清除微信信息 -->
    <el-dialog title="操作提示" :visible.sync="EnableDialogVisible" :close-on-click-modal="false" width="450px">
      <p style="color: #ff8080;margin-bottom: 25px;">说明：该操作将清除登录过该账号微信信息，如微信头像、昵称、openID等，清除后重新登录该账号的微信将被再次关联。</p>
      <div class="mb-20" style="text-align: center;">
        是否确认清除该账号关联的微信信息？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="EnableDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="EnableConfirmDialog">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 动态口令弹出框 -->
    <el-dialog :title='insideTitle' :visible.sync="insideDialogVisible" :close-on-click-modal="false" width="500px"
               @closed="insideClosed">
      <div class="enable-tip" v-if="insideType">
        <div>
          说明：启用后该商家店员可向关联回收商发送内部加价申请，与内部回收商成交的订单门店默认不留利润和红包（后返利模式）。若设置加价申请，则默认预留返利可申请且【按可申请比例输入范围内金额】加价。
        </div>
        <div class="tip-sure">是否确认启用内部回收功能？</div>
      </div>
      <div class="enable-tip" v-else>
        <div>说明：关闭后该门店商家员工不可再向回收商发送加价申请。</div>
        <div class="tip-sure">是否确认关闭该商家的内部回收功能？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="insideClosed">取消</el-button>
        <el-button type="primary" :loading="insideBtnLoading" @click.native="insideSubmit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title='启用提示' :visible.sync="enableShow" :close-on-click-modal="false" width="600px" @closed="enableClosed">
      <div class="enable-tip">
        <div>
          说明：如需开启内部回收，需要在加价申请设置中，则默认预留返利可申请且【按可申请比例输入范围内金额】加价。个性配置和公共配置统一为此设置。
        </div>
        <div class="tip-sure">{{ errorMsg }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="enableClosed">取消</el-button>
        <el-button type="primary" @click.native="enableTipSure">去设置</el-button>
      </span>
    </el-dialog>
    <el-dialog title='门店邀请商家设置' :visible.sync="inviteShow" :close-on-click-modal="false" width="585px"
               @closed="inviteClosed">
      <div class="flex mb-20 flex-as">
        <div style="width: 120px;">门店邀请回收商：</div>
        <div>
          <el-radio-group v-model="inviteParams.canInviteMerchant">
            <el-radio :label="true">可邀请</el-radio>
            <el-radio :label="false">不可邀请</el-radio>
          </el-radio-group>
          <div style="color: #FF8080;font-size:14px;">说明：设置不可申请，则小程序不显示邀请回收商按钮</div>
        </div>
      </div>
      <div class="flex mb-20 flex-as">
        <div style="width: 120px;">回收商邀请门店：</div>
        <div>
          <el-radio-group v-model="inviteParams.canInvitedByMerchant">
            <el-radio :label="true">可邀请</el-radio>
            <el-radio :label="false">不可邀请</el-radio>
          </el-radio-group>
          <div style="color: #FF8080;font-size:14px;">说明：设置不可申请，回收商发送合作申请，该门店商家点击不生效</div>
        </div>
      </div>
      <div class="command mb-20">
        <div style="width: 120px;text-align: right;">谷歌验证码：</div>
        <el-input size="small" v-model="inviteParams.command" placeholder="请输入谷歌动态口令"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')" clearable></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native=" inviteShow = false">取消</el-button>
        <el-button type="primary" :loading="inviteBtnLoading" @click.native="inviteSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 隐藏报价信息弹框 -->
    <el-dialog title='显示信息设置' :visible.sync="hidePriceShow" :close-on-click-modal="false" width="585px"
               @closed=" hidePriceShow = false">
      <div style="color: #FF8080;font-size:14px;margin-bottom: 20px;">说明：配置该商户的询价订单在回收端待报价列表</div>
      <div class="flex mb-20 flex-as">
        <div style="width: 270px;font-weight: 600;">待报价列表隐藏门店商户名称、门店名称：</div>
        <div>
          <el-radio-group v-model="hidePriceParams.waitQuoteHideCompany">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="flex mb-20 flex-as">
        <div style="width: 140px;font-weight: 600;">待报价列表隐藏机型：</div>
        <div>
          <el-radio-group v-model="hidePriceParams.waitQuoteHidePhoneName">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native=" hidePriceShow = false">取消</el-button>
        <el-button type="primary" @click.native="showCommandSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- 隐藏报价信息动态口令弹框 -->
    <el-dialog title="确认提示" :visible.sync="hidePriceCommandShow" :close-on-click-modal="false" width="480px">
      <div class="fzs16 mb20">是否确认修改？</div>
      <div class="command">
        <div>动态口令：</div>
        <el-input v-model="hidePriceParams.command" placeholder="请输入谷歌动态口令"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native=" hidePriceCommandShow = false">取消</el-button>
        <el-button type="primary" :loading="hidePriceButLoading" @click.native="hidePriceSubmit">确定</el-button>
      </span>
    </el-dialog>
    <!-- ***************** -->
    <el-dialog :title='onelineTitle' :visible.sync="onelineVisible" :close-on-click-modal="false" width="500px"
               @closed="onelineClosed">
      <div class="enable-tip" v-if="onelineTitle == '启用提示'">
        <div>说明：启用外部商户后，该商家作为繁星虚拟账户，仅用于外部关联订单存放。</div>
        <div class="tip-sure">是否确认启用该商家的外部商户？</div>
      </div>
      <div class="enable-tip" v-else>
        <div>说明：关闭后该商户合作商家不再接收商户关联的外部渠道询价订单</div>
        <div class="tip-sure">是否确认关闭该商家的外部商户？</div>
      </div>
      <div style="margin-bottom:20px;" v-if="onelineTitle == '启用提示'">
        <span>选择外部渠道：</span>
        <el-radio-group v-model="outType" :disabled="rowOutType">
          <el-radio label="xm">XM零售通渠道</el-radio>
          <el-radio label="ygx">一根线渠道</el-radio>
        </el-radio-group>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
                  oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click=" onelineVisible = false">取消</el-button>
        <el-button type="primary" :loading="onelineBtnLoading" @click.native="onelineSubmit">确定</el-button>
      </span>
    </el-dialog>
    <DialogTableSearch v-bind="matrixDialogProp" @close="matrixDialogProp.show = false;" @config="matrixConfig"
                       @sizeChange="(v) => { matrixDialogProp = { ...matrixDialogProp, ...v }; getMatrix(); }"
                       @pageChange="(v) => { matrixDialogProp = { ...matrixDialogProp, ...v }; getMatrix(); }">
      <template v-slot:header>
        <p class="tip">
          说明：开关/切换矩阵将会修改合作关系，请先确认商户无待确认状态订单
        </p>
        <div class="matrix-header">
          <h2>取价设置:</h2>
          <div>
            <span>第一次取价后是否再次推送更高价：</span>
            <el-switch active-text="开" inactive-text="关" inactive-color="#b6b9d0"
                       v-model="matrixDialogProp.isAgainPushHigh" @change="(v) => switchChange(v)">
            </el-switch>
          </div>
          <h2>选择使用矩阵:</h2>
        </div>
      </template>
      <!-- 弹窗插槽 -->
      <template v-slot="{ row, prop }">
        <div v-if="prop !== 'select'">
          {{ row[prop] }}
        </div>
        <div v-else>
          <el-checkbox :value="matrixDialogProp.selectMatrixId == row.id" @change="addSelectChange(row)" />
        </div>
      </template>
    </DialogTableSearch>

    <!-- 矩阵报价设置 -->
    <DialogAction v-bind="againPushHighProp" @close="againPushHighClose" @config="againPushHighConfig"></DialogAction>

    <div class="code-dialog-box">
      <!-- 启用、禁用提示 -->
      <DialogAction v-bind="codeProp" @close="codeProp.show = false" @config="codeConfig"
                    @codeChange="v => codeProp.code = v">
        <template v-slot:message>
          <div class="code_message_box">
            <i class="el-icon-warning-outline"/>
            <span v-if="codeProp.num>0">当前商户有<span class="lv_fc">{{codeProp.num}}单待确认</span>，如需切换矩阵报价，系统将自动取消订单。</span>
            <span v-else>当前商户<span class="lv_fc">无待确认订单</span>，切换矩阵后将由矩阵回收商参与报价。</span>
          </div>
        </template>
        <template v-slot:from>
          <div v-if="effectiveStatus == '01'" style="display: flex;align-items: center; margin-bottom: 22px">
            已有分转关系（分转生效中）
          </div>
          <div v-else-if="effectiveStatus == '00'" style="display: flex;align-items: center; margin-bottom: 22px">
            已有分转关系（分转未生效）
          </div>
          <div v-else-if="effectiveStatus == '02'" style="display: flex;align-items: center; margin-bottom: 22px">
            已有分转关系（分转已禁用）
          </div>
          <div v-else style="display: flex;align-items: center; margin-bottom: 20px">
            <span style="margin-right: 20px;transform: translateY(-3px)">是否启用分转到繁星仓库(立即生效)</span>
            <el-radio-group v-model="isDivide">
              <el-radio label="01">是</el-radio>
              <el-radio label="02">否</el-radio>
            </el-radio-group>
          </div>
        </template>
      </DialogAction>
    </div>
    <!-- 关联门店商户弹窗 -->
    <DialogAction v-bind="associationProp" @close="associationProp.show = false"
                  @codeChange="v => associationProp.code = v" @config="associationPropConfig" />
    <!-- 添加分转授权 -->
    <el-dialog title="添加分转授权" :visible.sync="sublicenseDialogVisible" :close-on-click-modal="false" width="700px"
               @closed="fenClosed">
      <el-form :model="formData" label-position="left" :rules="formRulesDialog" ref="ruleForm" label-width="100px"
               class="demo-ruleForm">
        <el-form-item label="选择分转商家" prop="companyId" label-width="110px">
          <el-input disabled v-model="formData.companyId" style="width:  100%" placeholder="请选择门店商家"></el-input>
        </el-form-item>
        <template v-if="formData.companyId">
          <div class="center_show">
            <div>联系人：{{ rowdata.contact }}</div>
            <div>联系电话：{{ rowdata.phone }}</div>
          </div>
        </template>
        <el-form-item label="授权回收商" prop="merchantIds">
          <!-- 改为多选 -->
          <el-input disabled v-model="formData.merchantIds" style="width:  100%" placeholder="请选择回收商家"></el-input>
        </el-form-item>
        <el-form-item label="设置收货地址" prop="price" label-width="105px">
          <span style="margin-left: -5px;">(若修改将同步更新该门店商家的全部的分转地址)</span>
        </el-form-item>
        <div class="ad_cstyle">
          <div class="adc_top">
            <div class="adc_line"></div>
            <span>当前地址</span>
            <div class="xiugai">
              <el-button @click="chansdreess" type="text">修改</el-button>
            </div>
          </div>
          <p style="margin-bottom: 10px;">{{ platAdres.contactName }}-{{ platAdres.contactPhone }}</p>
          <p>{{ platAdres.platformAddress }}{{ platAdres.houseAddress || " " }}</p>
        </div>
        <el-form-item label="生效时间" prop="effectiveTime">
          <div class="addFenTime">
            <el-date-picker :disabled="isImmediate" :picker-options="pickerOptions" v-model="formData.effectiveTime"
                            type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
            </el-date-picker>
            <div class="raido_inner" @click="addFenTimeChange">
              <div :class="isImmediate ? 'active_select' : ''"></div>
              <p>立即生效</p>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="sublicenseDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="fenConfirmDialog('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 选择修改地址 -->
    <el-dialog title="选择修改地址" :visible.sync="actionDialogVisible" :close-on-click-modal="false" width="700px"
               @closed="cangeClosed">
      <div class="ad_cstyle actionstyle displaghf" v-for="(item, index) in adressList" :key="index">
        <div>
          <div class="adc_top">
            <div class="adc_line"></div>
            <span v-if="item.id == platAdres.id">当前地址</span>
            <span v-else>地址{{ index + 1 }}</span>
            <!-- <div class="xiugai"><el-button type="text">编辑</el-button></div> -->
          </div>
          <p style="margin-bottom: 10px;">{{ item.contactName }}-{{ item.contactPhone }}</p>
          <p>{{ item.platformAddress }}{{ item.houseAddress || " " }}</p>
        </div>
        <div class="im_actin" v-if="item.id !== platAdres.id" @click="adSlect(index, item)">
          <img v-if="adreeId === index" src="./image/sel_duox_pre.png" alt="">
          <img v-else src="./image/sel_duox.png" alt="">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="actionDialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="handleConfirmDialog">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 启用分转提示 -->
    <el-dialog title='启用分转提示' :visible.sync="turnonShow" :close-on-click-modal="false" width="400px">
      <div class="enable-tip">
        <div>说明：添加授权分转后，默认启用该分转授权，按照设置生效时间进行设置。</div>
        <div class="tip-sure">是否确认启用发货分转到平台？</div>
      </div>
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input v-model="forommand" placeholder="请输入谷歌动态口令"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="turnonShow = false">取消</el-button>
        <el-button type="primary" :loading="enableButLoading" @click.native="turnonSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Provinces from "@/components/plugins/provinces";
import ElImgUpload from "../../components/global/components/elements/upload-element.vue";
import MinXinRequest from "@/mixins/request";
import _api from "@/utils/request";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import DialogTableSearch from "@/components/common/DialogTableSearch.vue";
import { againPushHighProp, associationProp, codeProp, matrixDialogProp } from "@/views/StoreShop/initState";
import { getMeta, setValue } from "@/utils/common";
import DialogAction from "@/components/common/DialogAction.vue";
import moment from "moment/moment";
import {openAction} from "../../utils/common"

let checkNum = (rule, value, callback) => {
  if (value == 0 || value > 100) {
    callback(new Error('加价比例必须大于0小于等于100'))
  } else {
    callback()
  }
}
export default {
  name: "Table",
  components: { DialogAction, DialogTableSearch, ElImageViewer, ElImgUpload, Provinces },
  mixins: [MinXinRequest],
  data() {
    return {
      EnableDialogVisible: false,//清除微信消息
      WeChatid:"",//店员ID
      estimateVisible: false,//启用预估价下单模式弹窗
      isExport: false,//店员列表导表
      adreeId: 0,
      forommand: "",
      turnonShow: false,
      enableButLoading: false,
      adressList: [],
      // 立即生效
      isImmediate: null,
      platAdres: {
        contactName: ''
      },
      sublicenseDialogVisible: false,
      actionDialogVisible: false,
      formData: {
        addressId: "",
        command: "",
        companyName: "",
        companyId: "",
        merchantName: "",
        contactName: "",
        contactPhone: "",
        effectiveTime: "",
        id: "",
        merchantId: '',//编辑时
        address: {}
      },
      formRulesDialog: {
        companyId: [
          {
            required: true,
            trigger: "blur",
            message: "请选择门店商家",
          },
        ],
        merchantId: [
          {
            required: true,
            trigger: "blur",
            message: "请选择回收商家",
          },
        ],
        effectiveTime: [
          {
            required: true,
            trigger: "blur",
            message: "请选择生效时间",
          },
        ],
      },
      // 当前选择行数据(回收商添加)
      checkData: null,
      // 添加分转弹窗
      isEffectiveShow: false,
      //分转状态
      effectiveStatus: null,
      // 分转关联
      isDivide: "",
      // 关联商户动态口令
      associationProp,
      // 动态口令
      codeProp,
      // 报价弹窗控制
      againPushHighProp,
      // 矩阵弹窗
      matrixDialogProp,
      //压价
      yajiaShow: false,
      yaCommand: "",
      proportion: 1,
      pattern: 1,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      editPricerules: {
        hideQuoteRatio: [
          { required: true, message: "请输入压价隐藏比例", trigger: "blur" },
          { type: "number", validator: checkNum, trigger: "blur" },
        ],
        hideQuoteLimit: [
          {
            required: true, message: "请输入压价上限", trigger: "blur"
          },
        ]
      },
      editPriceForm: {
        startQuote: "",
        endQuote: "",
        hideQuoteRatio: "",
        hideQuoteLimit: "",
      },
      addPriceShow: false,
      regionList: [],
      regionColumns: [
        { slotName: "rank" },
        { slotName: "hideQuoteRatio" },
        { slotName: "markUpLimit" },
        { slotName: "hideQuoteLimit" },
        { slotName: "operation" },
      ],
      // 隐藏报价信息
      hidePriceShow: false,
      hidePriceCommand: '',
      hidePriceCommandShow: false,
      hidePriceButLoading: false,
      // 隐藏机型参数
      hidePriceParams: {
        command: '',
        waitQuoteHideCompany: true,//是否隐藏商家信息
        waitQuoteHidePhoneName: true//是否隐藏机型名称
      },
      // ************************
      tableColumns2: [
        // { label: "申请商家", prop: "companyName" },
        { slotName: "rio" },
        { label: "回收商名称", prop: "merchantName" },
        { label: "联系人", prop: "contactName" },
        { label: "电话", prop: "contactPhone" },
        { label: "地址", prop: "fullAddress" },
        { label: "入驻时间", prop: "entryTime" },
        // { slotName: "Img" },
        { slotName: "auditStatus" },
      ],
      page2: {
        pageSize: 5,
        total: 0,
        pageNum: 1
      },
      Googleid: "",
      rules: {
        name: [
          { required: true, message: "请输入谷歌验证码", trigger: "blur" },
        ],
      },
      radiostore: "",
      checkList: [],
      checked: "",
      tableData: [],
      username: "",
      usedType: null,
      emType: null,
      emnateid: null,
      insideType: null,
      insideCompanyId: "",
      Addpartnershow: false,
      inviteBtnLoading: false,
      inviteShow: false,
      inviteParams: {
        canInviteMerchant: true,
        canInvitedByMerchant: true,
        command: "",
      },
      RemarksTxt: "", //备注value
      RemarksShow: false, //备注提示框显示变量
      RemarkslogTxt: "", //备注提示框显示value
      DisableShow: false, //启用禁用提示框显示变量
      commandShow: false, // 强退弹框
      butLoading: false, // 强退弹框按钮
      insideBtnLoading: false,
      usedDialogVisible: false,//正式启用弹框
      insideDialogVisible: false,//内部回收弹框
      enableShow: false,
      errorMsg: "",
      insideRow: null,
      insideTitle: "",
      usedCommand: "",
      emCommand: "",
      usedCompanyId: "",
      disableNote: "", //禁用备注
      isEnable: null, //启用禁用状态
      RemarksTitle: "",
      loginMobilebool: false,
      clickLogo: "",
      MerchantFormData: [],
      optionsProvince: [],
      optionsCity: [],
      optionsArea: [],
      companyList: [],
      staffName: "",
      addressInfo: {
        provinceID: "",
        cityID: "",
        areaID: "",
      },
      isOpenNiceBizD: false,
      isOpen: false,
      dialogImageUrl: "",
      businessLicense: "",
      dialogVisible1: false,
      disabled: false,
      loading: false,
      storeLoading: false,
      staffLoading: false,
      seachDataList: [],
      dialogTitle: "",
      account: {
        acc: "",
        pwd: "",
      },
      formOpenPc: {
        id: "",
        isOpenPc: "",
        command: "",
      },
      command: "",
      loginOutCommand: "",
      tipTxt: "",
      tipOpenPc: "",
      isOpenPc: "",
      dialogVisible: false,
      staffListType: "",
      //靓机 - 开启
      dialogVisibleB: false, //靓机
      openorclose: "开启提示",
      BeautifulShow: false,
      dialogPayModeVisible: false,
      isOpenNiceBiz: null,
      BianjiText: false,
      companyName: "",
      contactName: "",
      contactPhone: 1,
      merchantAddress: "null",
      loginMobile: "",
      storeSendSet: "00",//门店发货设置 00发往靓机回收商，01发往成交回收商
      merchantName: "",
      //靓机 - 结束
      imgList: [],
      showViewer: false,
      pageNum: 1,
      tableColumns: [
        // { label: '商家名称', prop: 'companyName' },
        { slotName: "companyName" },
        { label: "联系人", prop: "contact" },
        { label: "联系电话", prop: "phone" },
        { slotName: "provinceCityDistrict" },
        { slotName: "operationAddress" },
        { label: "入驻时间", prop: "createTime" },
        { slotName: "operator" },
        { slotName: "stores" },
        { slotName: "staffs" },
        { slotName: "recycles" },
        // {label: '累计交易（笔）', prop: 'recycles'},
        { slotName: "operation" },
      ],
      storeParams: {},
      deleteVisible: false,
      dialogStoreList: [],
      storesColumns: [
        { slotName: "areaName" },
        { slotName: "storeName" },
        { slotName: "storeAddress" },
        { slotName: "storeManager" },
        { slotName: "storeManagerPhone" },
        { slotName: "staffCount" },
        { label: "添加时间", prop: "createTime" },
      ],
      staffParams: {
        storeId: "",
        pageSize: 10,
      },
      staffVisible: false,
      dialogStaffList: [],
      staffColumns: [],
      companyId: "",
      staffMobile: "",
      isSubscribe: "",
      companyName2: "",
      pwdFlag: false,
      isReset: false,
      isOpenB: null,
      // 门店url地址弹框
      storeUrl: "",
      storeUrlShow: false,
      inspectorId: "",
      storeDialogType: "01",
      rowdata: {},
      Google: false,
      loginOutId: "",
      onelineTitle: "",
      onelineVisible: false,
      onelineBtnLoading: false,
      rowOutType: "",
      outType: "",
      editPriceShow: false,
      ifedmit: true,
      downpriceid: "",
      configAlldata: {},
      currentData: {},
    };
  },
  created() {
    this.handleCurrentChange();
    // this.getplantAddress();
    _api
        .getcompanylist({
          pageNum: 1,
          pageSize:10
        })
        .then((res) => {
          console.log(res);
        });
  },
  mounted() {
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
    ownedOperator() {
      return (operator) =>
          ({ "01": "移动", "02": "联通", "03": "电信", "04": "其他" }[operator]);
    },
    type() {
      return this.pwdFlag ? "password" : "text";
    },
    elIcon() {
      return this.pwdFlag ? "el-icon-minus" : "el-icon-view";
    },
    maxHideQuoteLimit() {
      const { endQuote, hideQuoteRatio } = this.editPriceForm;
      if ((endQuote || Number(endQuote) === 0) && (hideQuoteRatio || hideQuoteRatio === 0)) {
        return Math.floor(endQuote * (hideQuoteRatio / 100));
      }
      return null
    }
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.pageNum = 1;
      this.handleCurrentChange();
    },
    maxHideQuoteLimit(v) {
      const { hideQuoteLimit } = this.editPriceForm;
      if ((v || Number(v) === 0) && hideQuoteLimit > v) {
        this.editPriceForm.hideQuoteLimit = v;
      }
    },
    isImmediate(v) {
      if (v) {
        this.formData.effectiveTime = moment().format("YYYY-MM-DD");
      }
    }
  },
  methods: {
    openAction,
    //选择修改地址
    adSlect(index, data) {
      console.log(index, data);
      this.adreeId = index
    },
    //压价
    limitInput(value, name) {
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this.editPriceForm[name] = ""
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有两位小数
      let valJoin = value.join("");
      // this.editPriceForm[name] =value.replace(/[^0-9]/g,'')
      this.editPriceForm[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || "";
      if (this.editPriceForm[name] >= 100) {
        this.editPriceForm[name] = '99'
      }
    },
    hideQuoteLimitChange(v) {
      if (isNaN(Number(v))) {
        return this.editPriceForm.hideQuoteLimit = null;
      }
      if (parseInt(v) < 0) {
        return this.editPriceForm.hideQuoteLimit = null;
      }
      if ((this.maxHideQuoteLimit || parseInt(this.maxHideQuoteLimit) === 0) && Number(v) > this.maxHideQuoteLimit) {
        return this.editPriceForm.hideQuoteLimit = this.maxHideQuoteLimit;
      }
      if (!this.maxHideQuoteLimit) {
        this.editPriceForm.hideQuoteLimit = v;
      }
    },
    // 失焦
    inputBlur(name) {
      console.log(this.editPriceForm[name]);
      if (this.editPriceForm[name].toString().indexOf('.') == (this.editPriceForm[name].length - 1)) {
        this.editPriceForm[name] = this.editPriceForm[name].replace('.', '')
      }
    },
    //确认压价动态口令
    yaPricesure() {
      if (!this.yaCommand) {
        this.$message.error('请输入动态口令！');
        return
      }
      const params = {
        "command": this.yaCommand,
        "companyId": this.downpriceid,
        "isStoreShowQuoteConfig": "",
        "isUnify": "",
        "quoteConfigDtos": []
      }
      params.isStoreShowQuoteConfig = this.proportion == 1 ? '01' : '00'
      params.isUnify = this.pattern == 1 ? '01' : '00'
      if (this.pattern == 1) {
        params.quoteConfigDtos = this.regionList
      }
      _api.saveyajiaConfig(params).then(res => {
        if (res.code == 1) {
          this.addPriceShow = false
          this.yajiaShow = false
          this.$message({
            message: "保存成功！",
            type: "success",
          });
        }
      })
    },
    //确认压价
    submitAddPrice() {
      this.yajiaShow = true
      this.yaCommand = ""
    },
    // 编辑弹框确认
    submitEditPrice() {
      this.$refs["editPriceRulesForm"].validate((valid) => {
        if (valid) {
          if (this.ifedmit) {
            this.regionList.forEach(item => {
              if (item.id == this.rowId) {
                item.hideQuoteRatio = this.editPriceForm.hideQuoteRatio
                item.quoteRatio = 100 - Number(this.editPriceForm.hideQuoteRatio)
                item.startQuote = this.editPriceForm.startQuote
                item.endQuote = this.editPriceForm.endQuote
                item.hideQuoteLimit = this.editPriceForm.hideQuoteLimit
              }
            })
          } else {
            if (!this.editPriceForm.startQuote || !this.editPriceForm.endQuote) {
              this.$message.error('请填写最高门店显示价区间！');
              return
            }
            if (this.editPriceForm.startQuote && this.editPriceForm.endQuote && (Number(this.editPriceForm.endQuote) <= Number(this.editPriceForm.startQuote))) {
              this.$message.error('最高门店显示价必须大于最低门店显示价！');
              return
            }
            this.regionList.push({
              hideQuoteRatio: this.editPriceForm.hideQuoteRatio,
              quoteRatio: 100 - Number(this.editPriceForm.hideQuoteRatio),
              startQuote: this.editPriceForm.startQuote,
              endQuote: this.editPriceForm.endQuote,
              id: this.regionList.length + "yj",
              hideQuoteLimit: this.editPriceForm.hideQuoteLimit
            })
          }
          this.editPriceShow = false
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭编辑
    editPriceClosed() {
      this.$refs["editPriceRulesForm"].resetFields();
      this.rowId = ""
      this.editPriceForm = {
        startQuote: "",
        endQuote: "",
        hideQuoteRatio: "",
      }
    },
    // 打开压价设置弹框
    openAddPrice(row) {
      this.downpriceid = row.id
      this.addPriceShow = true;
      _api.getdownpriceConfig({ companyId: row.id }).then(res => {
        if (res.code == 1) {
          console.log(res);
          this.configAlldata = JSON.parse(JSON.stringify(res.data))
          this.proportion = this.configAlldata.isStoreShowQuoteConfig == '01' ? 1 : 2
          this.pattern = this.configAlldata.isUnify == '01' ? 1 : 2
          this.regionList = res.data.quoteConfigDtos || []
        }
      })
    },
    //删除压价
    deletConfig(row) {
      this.regionList.forEach((res, index) => {
        if (res.id == row.id) {
          this.regionList.splice(index, 1)
        }
      })
    },
    // 压价配置编辑
    editConfig(row) {
      this.editPriceShow = true
      if (row) {
        this.ifedmit = true
        this.rowId = row.id
        this.editPriceForm = {
          startQuote: row.startQuote,
          endQuote: row.endQuote,
          hideQuoteRatio: row.hideQuoteRatio,
          hideQuoteLimit: row.hideQuoteLimit
        }
      } else {
        this.ifedmit = false
      }
    },
    // 隐藏报价信息
    handleCurrentChange2(val) {
      if (val) {
        this.page2.pageNum = val;
      } else {
        this.page2.pageNum = 1;

      }
      let params = {
        pageNum: this.page2.pageNum,
        pageSize: 5,
        merchantType: "02",
        isNiceMerchant: '02',
        merchantName: this.username,
        queryOrderInfo: false,
        isFilterMatrix: '01',//过滤矩阵商家
      };
      _api.ShopTable(params).then((res) => {
        console.log(res);
        if (res.code == 1) {
          let arr = res.data.records;
          arr.forEach((item) => {
            item.checked = false;
          });
          this.tableData = arr;
          this.page2.pageSize = res.data.size;
          this.page2.total = res.data.total;
          this.page2.pageNum = res.data.current;
        }
      });
    },

    // 分转详情
    getCompanyAndMerchant(companyId, merchantId, cb) {
      _api.getMiddleByCompanyAndMerchant(companyId, merchantId).then(r => {
        if (r.data) {
          this.effectiveStatus = r.data.effectiveStatus;
        } else {
          // 不存在分转关系
          this.effectiveStatus = '04'
        }
        cb();
      })
    },

    // TODO 谷歌验证
    Addpartitle() {
      this.isDivide = null;
      // 重置是古分转选择项数据
      this.isDivide = null;
      if (this.checkList.length) {
        this.getCompanyPlatformAddress(this.rowdata.id, () => {
          // 分转详情
          this.getCompanyAndMerchant(this.rowdata.id, this.checkList[0], () => {
            this.Google = true;
          });
        });
      } else {
        this.$message({
          message: "请选择合作商！",
          type: "warning",
        });
      }
    },

    // 单选
    checkBoxChange(val, row) {
      // 控制 单选
      if (this.checkList.indexOf(val) > -1) {
        this.checkList = [];
        this.checkList.push(val);
        this.checkData = row;
        console.log(row)
      }
      console.log(this.checkList);
    },

    //关闭选择修改地址
    cangeClosed() {
      this.adreeId = ""
      console.log(this.adreeId);
    },
    handleConfirmDialog() {
      console.log(this.adreeId);
      if (this.adreeId !== "") {
        this.platAdres = JSON.parse(JSON.stringify(this.adressList[this.adreeId]))
      }
      this.actionDialogVisible = false
    },
    // TODO添加合作商
    AddparcheckFn() {
      if (!this.isDivide && this.effectiveStatus === '04') {
        return this.$message.error("请选择分转繁星仓库操作");
      }
      if (this.rowdata.payApplyStatus == '02' && !this.radiostore) {
        this.$message.error("请选择手续费承担方！")
        return;
      }
      this.isImmediate = null;
      let params = {
        command: this.Googleid,
        companyId: this.rowdata.id,
        merchantId: this.checkList[0],
        processFeeBear: this.radiostore,
      };
      _api.saveUnionMerchant(params).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "添加成功！",
            type: "success",
          });
          this.Googleid = "";
          this.radiostore = "";
          this.Google = false;
          this.checkList = [];
          this.Addpartnershow = false;
          this.handleCurrentChange();
          if (this.isDivide === '01') {
            this.formData.companyId = this.rowdata.companyName;
            this.formData.merchantIds = this.checkData.merchantName
            this.sublicenseDialogVisible = true;
          }
          console.log(this.checkData)
        } else {
          this.Googleid = "";
          this.radiostore = "";
          this.$message.error(res.msg || "添加失败！");
        }
      })
    },

    //查询平台商家地址
    // getplantAddress() {
    //   _api.queryFormAddress().then(res => {
    //     this.adressList = res.data;
    //     // this.platAdres = JSON.parse(JSON.stringify(this.adressList[0]))
    //   })
    // },

    fenClosed() {
      this.formData = {
        companyId: "",
        merchantIds: [],
        addressId: "",
        effectiveTime: "",
      }
      this.optionsSm = []
    },
    //修改地址
    chansdreess() {
      this.actionDialogVisible = true
    },
    acConfirmDialog() {
      console.log(this.adreeId, this.adressList[this.adreeId]);
      if (this.adreeId !== "") {
        this.platAdres = JSON.parse(JSON.stringify(this.adressList[this.adreeId]))
        this.formData.address = JSON.parse(JSON.stringify(this.adressList[this.adreeId]))
      }
      console.log(this.formData.address, "this.formData.address");
      this.actionDialogVisible = false
    },
    //获取门店商平台收货地址
    getCompanyPlatformAddress(id, cb) {
      _api.getCompanyPlatformAddress({ companyId: id }).then(res => {
        // 未是否分配地址
        if (!res.data) {
          this.platAdres = JSON.parse(JSON.stringify(this.adressList[0]))
        } else {
          this.platAdres = res.data
        }
        cb();
      })
    },
    //addpar username find
    onSubmit() {
      let params = {
        pageNum: 1,
        pageSize: 5,
        merchantName: this.username,
        merchantType: "02",
        isNiceMerchant: '02',
        queryOrderInfo: false
      };
      this.Addpartner(this.rowdata, params);
    },
    // 生效时间
    addFenTimeChange() {
      this.isImmediate = !this.isImmediate;
    },

    //分转授权
    fenConfirmDialog(formName) {
      console.log(this.formData);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formData.addressId = this.platAdres.id
          this.formData.contactName = this.rowdata.contact
          this.formData.contactPhone = this.rowdata.phone
          this.turnonShow = true
          this.forommand = null;
        } else {
          return false;
        }
      });
    },

    //创建分转
    turnonSubmit() {
      console.log(this.formData);
      this.formData.command = this.forommand
      this.fenParams = JSON.parse(JSON.stringify(this.formData))
      this.fenParams.effectiveTime = moment(this.fenParams.effectiveTime).format('x')
      console.log(this.fenParams);
      let params = {
        isImmediate: this.isImmediate
      };
      if (this.isImmediate) {
        params = {
          ...params,
          ...this.fenParams,
          effectiveTime: undefined,
        }
      } else {
        params = {
          ...params,
          ...this.fenParams,
        }
      }
      params = {
        ...params,
        merchantIds: [this.checkData.merchantId],
        companyId: this.rowdata.id
      }
      _api.saveMiddle(params).then(res => {
            if (res.code == 1) {
              this.$message({
                message: '创建成功！',
                type: 'success'
              });
              this.forommand = ""
              this.turnonShow = false
              this.sublicenseDialogVisible = false
              this.handleCurrentChange(this.pageNum);
              this.isImmediate = null;
            }
          }
      )
    },

    //addpar close
    Addpartnerclose() {
      this.username = '';
      this.Addpartnershow = false;
      this.checkList = [];
    },
    // ----------------------->添加合作商
    Addpartner(v, paramss) {
      this.rowdata = v;
      this.Addpartnershow = true;
      this.checkData = null;
      this.currentData = v
      let params = {};
      if (paramss) {
        params = paramss;
      } else {
        params = { pageNum: 1, pageSize: 5, merchantType: "02", isNiceMerchant: '02', queryOrderInfo: false };
      }
      params.isFilterMatrix = '01';//过滤矩阵商家
      _api.ShopTable(params).then((res) => {
        console.log(res);
        if (res.code == 1) {
          let arr = res.data.records;
          arr.forEach((item) => {
            item.checked = false;
          });
          this.tableData = arr;
          this.page2.pageSize = res.data.size;
          this.page2.total = res.data.total;
          this.page2.pageNum = res.data.current;
        }
      });
    },

    // ----------------------x添加备注
    RemarksAdd(row) {
      this.RemarksTitle = "添加备注";
      console.log(row, "备注");
      this.RemarksShow = true;
      this.companyId = row.id;
      this.RemarkslogTxt = "";
      console.log(this.companyId);
    },
    // -------------------->添加弹窗取消
    Remarks_log_cl(row) {
      this.RemarksShow = false;
    },
    // -------------------->添加弹窗确认
    Remarks_log_sure(row) {
      console.log("添加弹窗确认");
      console.log(this.RemarkslogTxt);
      this.RemarksTxt = this.RemarkslogTxt;

      _api
          .setmasterNote({
            companyId: this.companyId,
            masterNote: this.RemarkslogTxt,
          })
          .then((res) => {
            if (res.code == 1) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.RemarksShow = false;
            } else {
              this.$message({
                type: "erro",
                message: res.msg || "操作失败!",
              });
            }
          });
    },

    // ----------------------x修改备注
    RemarksModify(row) {
      console.log(row);
      this.RemarksTitle = "修改备注";
      this.companyId = row.id;
      this.RemarkslogTxt = row.masterNote;
      this.RemarksShow = true;
    },
    // ----------------------x禁用
    DisableAdd(row) {
      this.DisableShow = true;
      console.log(row, "禁用");
      this.companyId = row.id;
      this.isEnable = !row.isEnable;
    },
    Disableopen(row) {
      this.DisableShow = true;
      this.companyId = row.id;
      this.isEnable = !row.isEnable;
    },
    // -------------------->启用弹窗取消
    Disable_log_cl(row) {
      this.DisableShow = false;
    },
    // -------------------->启用弹窗确认
    Disable_log_sure(row) {
      console.log(row, "启用");

      if (!this.disableNote && !this.isEnable) {
        this.$message({
          type: "warning",
          message: "请输入禁用原因!",
        });
        return;
      }

      if (!this.formOpenPc.command) {
        this.$message({
          type: "warning",
          message: "请输入动态口令!",
        });
        return;
      }
      _api
          .saveenablecompany({
            command: this.formOpenPc.command,
            companyId: this.companyId,
            disableNote: this.disableNote,
            isEnable: this.isEnable,
          })
          .then((res) => {
            if (res.code) {
              console.log(res);
              this.DisableShow = false;
              this.$message({
                type: "success",
                message: res.msg || "操作成功",
              });
              this.disableNote = "";
            }
          });
    },

    handleDelete(e) {
      console.log(e, "删除他");
      this.dialogImageUrl = "";
    },

    provinclick(e) {
      console.log(e);
    },

    handleChange(e) {
      console.log(e);
      this.dialogImageUrl = e;
      this.dialogVisible1 = true;
      console.log(
          this.dialogImageUrl,
          "==============================================="
      );
    },
    changNo() {
      this.$forceUpdate(0);
    },

    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      console.log(123);
      this.dialogImageUrl = file.url;
      this.dialogVisible1 = true;
      console.log(
          this.dialogImageUrl,
          "==============================================="
      );
    },
    handleDownload(file) {
      console.log(file);
    },
    // // 省市区
    // provincesMount() {
    //   _api.listAudit(0).then((res) => {
    //     if (res.code === 1) {
    //       this.optionsProvince = res.data
    //     }
    //   })
    //   _api.listAudit(this.MerchantFormData.provinceId).then((res) => {
    //     this.optionsCity = res.data
    //   })
    //   _api.listAudit(this.MerchantFormData.cityId).then((res) => {
    //     this.optionsArea = res.data
    //   })
    // },
    areaChild(val) {
      this.addressInfo.areaID = val;
    },
    provinceChild(val) {
      this.addressInfo.provinceID = val;
      this.addressInfo.cityID = "";
      this.addressInfo.areaID = "";
      _api.listAudit(val).then((res) => {
        if (res.code === 1) {
          this.optionsCity = res.data;
        }
      });
    },
    cityChild(val) {
      this.addressInfo.cityID = val;
      _api.listAudit(val).then((res) => {
        if (res.code === 1) {
          this.optionsArea = res.data;
        }
      });
    },

    // 表格切换页面
    handleCurrentChange(val) {
      this.loading = true;
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const { matrixId } = this.$route.query;
      const params = {
        pageNum: this.page.pageNum,
        pageSize: this.SeachParams.pageSize,
        companyName: this.SeachParams.companyName,
        contact: this.SeachParams.contact,
        phone: this.SeachParams.phone,
        channel: this.SeachParams.channel,
        isSymbolUsed: this.SeachParams.isSymbolUsed,
        isOpenPc: this.SeachParams.isOpenPc,
        orderType: this.SeachParams.orderType,
        startRecycles: this.SeachParams.startRecycles,
        endRecycles: this.SeachParams.endRecycles,
        matrixId: this.SeachParams.matrixId,
        cityId: this.SeachParams.cityId,
        provinceId: this.SeachParams.provinceId
      };
      this.$emit("handleCurrentChange", val);
      _api.getcompanylist(params).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          // this.$store.commit('tagsView/POST_MERCHANTDATA', this.seachDataList)
          this.loading = false;
        }
      });
    },
    getStoreList(val) {
      this.storeLoading = true;
      const params = {
        inspectorId: this.inspectorId,
        companyId: this.storeParams.companyId,
        pageNum: val || 1,
        pageSize: this.storeParams.pageSize,
      };
      _api.getStoreList(params).then((res) => {
        if (res.code === 1) {
          this.dialogStoreList = res.data.records;
          this.storeParams.total = res.data.total;
          this.storeParams.pageNum = res.data.current;
          // this.$store.commit('tagsView/POST_MERCHANTDATA', this.seachDataList)
          this.storeLoading = false;
        }
      });
    },
    getStaffList(val) {
      this.staffLoading = true;
      const params = {
        companyId: this.storeParams.companyId,
        storeId: this.staffParams.storeId,
        staffName: this.staffName,
        staffMobile: this.staffMobile,
        isSubscribe: this.isSubscribe,
        pageNum: val || 1,
        pageSize: this.storeParams.pageSize,
      };
      _api.getStaffPreson(params).then((res) => {
        if (res.code === 1) {
          this.dialogStaffList = res.data.records;
          this.staffParams.total = res.data.total;
          this.staffParams.pageNum = res.data.current;
          // this.$store.commit('tagsView/POST_MERCHANTDATA', this.seachDataList)
          this.staffLoading = false;
        }
      });
    },
    // 表格操作
    handleBtn(val) {
      console.log(val);
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(val.businessLicense);
    },
    handleDialog(val) {
      this.$emit("handleDialog", val);
    },
    onClose() {
      this.deleteVisible = false;
    },
    staffClose() {
      this.isSubscribe = ""
      this.staffName = ""
      this.staffMobile = ""
      this.staffParams.storeId = ""
      this.staffVisible = false;
    },
    storeDialog(row, type) {
      this.storeDialogType = type;
      if (type == "02") {
        this.inspectorId = row.staffId;
      } else {
        this.storeParams.companyId = row.id;
        this.inspectorId = "";
      }
      this.deleteVisible = true;
      this.getStoreList();
    },
    staffDialog(row, val) {
      console.log(row);
      this.staffVisible = true;
      this.staffListType = val;
      if (val == "company") {
        this.companyId = row.id;
        this.storeParams.companyId = row.id;
        this.staffParams.storeId = "";
        this.staffColumns = [
          { slotName: "staffName" },
          // {label: '姓名', prop: 'staffName'},
          { label: "手机号", prop: "staffMobile" },
          //{ label: "累计成交订单", prop: "recycles" },
          { label: "注册时间", prop: "entryTime" },
          //{ label: "成交率（%）", prop: "recycleRate" },
          { slotName: "storeName" },
          { slotName: "lastLoginTime" },
          { slotName: "lastCreateOrderTime" },
          { slotName: "isSubscribe" },
          { slotName: "operation" },
        ];
        this.getStoreSelectList();
      } else {
        this.staffParams.storeId = row.id;
        this.staffColumns = [
          { label: "店员姓名", prop: "staffName" },
          { label: "登录手机号", prop: "staffMobile" },
          { label: "入驻时间", prop: "entryTime" },
        ];
      }
      this.getStaffList();
    },
    toLink(row) {
      console.log(row);
      this.$router.push({
        path: "/RecallOrder/index",
        query: {
          companyId: row.id,
        },
      });
    },
    branchAcc(row) {
      console.log(row);
      this.dialogTitle = row.isAssignAccount ? "查看账号" : "分配账号";
      if (row.isAssignAccount) {
        this.dialogTitle = "查看账号";
        this.account = {
          acc: row.userAcc,
          pwd: row.majorMobile,
        };
        this.pwdFlag = true;
      } else {
        this.dialogTitle = "分配账号";
        this.account = {
          acc: "",
          pwd: row.majorMobile,
        };
      }
      this.tipTxt = "开启门店商家管理后台，分配账号为门店端创建者。";

      this.dialogVisible = true;
      this.companyId = row.id;
    },
    handleClosed() {
      this.account = {
        acc: "",
        pwd: "",
      };
      (this.formOpenPc = {
        id: "",
        isOpenPc: "",
        command: "",
      }),
          (this.pwdFlag = false);
      this.isReset = false;
      this.dialogVisible = false;
      this.usedDialogVisible = false
    },
    //关闭启用预估价下单模式弹窗
    estimateClosed() {
      this.estimateVisible = false
      this.emCommand = ""
      this.handleCurrentChange(this.pageNum);
    },
    handleDialogClosed() {
      this.account = {
        acc: "",
        pwd: "",
      };
      (this.formOpenPc = {
        id: "",
        isOpenPc: "",
        command: "",
      }),
          (this.pwdFlag = false);
      this.isReset = false;
      this.dialogVisible = false;
      this.usedCommand = ""
      this.handleCurrentChange(this.pageNum);
    },
    // 点击生成账号
    createPcAcc() {
      _api.createPcAcc({ id: this.companyId }).then((res) => {
        if (res.code == 1) {
          this.account.acc = res.msg;
        }
      });
    },
    // 确定分配账号
    handleSuccess() {
      let params = {
        companyId: this.companyId,
        userAcc: this.account.acc,
        userPwd: this.account.pwd,
        reset: this.isReset,
      };
      if (this.dialogTitle == "提示") {
        if (!this.formOpenPc.command) {
          this.$message.error("请输入动态口令");
          return;
        }
        _api.updateIsOpenPc(this.formOpenPc).then((res) => {
          this.$message.success(res.msg || "操作成功");
          this.dialogVisible = false;
        });
      } else if (this.dialogTitle == "查看账号" && this.isReset) {
        this.$confirm("此操作将重置门店商家密码, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              _api.assignStaffAcc(params).then((res) => {
                this.$message({
                  type: "success",
                  message: "重置成功!",
                });
                this.dialogVisible = false;
              });
            })
            .catch(() => {
            });
      } else if (this.dialogTitle == "查看账号") {
        this.dialogVisible = false;
      } else {
        _api.assignStaffAcc(params).then((res) => {
          this.$message.success(res.msg || "操作成功");
          this.dialogVisible = false;
        });
      }
    },
    handleSwitch(row) {
      // console.log(row.id);
      this.dialogTitle = "提示";
      this.tipTxt = "启用后须分配账号给门店创建者，才可使用系统。";
      if (row.isOpenPc) {
        this.tipOpenPc = "启用";
      } else {
        this.tipOpenPc = "禁用";
      }
      this.formOpenPc.id = row.id;
      this.formOpenPc.isOpenPc = row.isOpenPc;
      this.dialogVisible = true;
    },
    handleSwitchUsed(row) {
      this.usedType = row.isSymbolUsed
      this.usedCompanyId = row.id;
      this.usedDialogVisible = true
    },
    //确认启用预估价下单模式
    estimateSuccess() {
      if (!this.emCommand) {
        return this.$message.error("请输入动态口令");
      }
      _api
          .updateUseEstimatedMode({
            id: this.emnateid,
            useEstimatedMode: this.emType,
            command: this.emCommand,
          })
          .then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg || "操作成功");
              this.estimateVisible = false
            } else {
              this.$message.success(res.msg || "操作成功");
              this.estimateVisible = false
            }
          });
    },
    //启用预估价下单模式
    hpreEstimateSwitchUsed(row) {
      this.emType = row.useEstimatedMode
      this.emnateid = row.id;
      this.estimateVisible = true
    },
    // 内部回收
    switchInsideBiz(row) {
      this.insideRow = row
      this.insideType = row.isOpenInsideBiz
      this.insideTitle = row.isOpenInsideBiz ? '启用提示' : '关闭提示'
      this.insideCompanyId = row.id;
      this.insideDialogVisible = true
    },
    // 弹框关闭
    insideClosed() {
      this.insideDialogVisible = false
      this.command = ""
      this.handleCurrentChange(this.pageNum);
    },
    insideSubmit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return
      }
      this.insideBtnLoading = true
      _api.updateCompanyIsOpenInsideBiz({
        id: this.insideCompanyId,
        isOpen: this.insideType,
        command: this.command,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg || "操作成功");
          this.insideDialogVisible = false
        } else {
          this.$message.success(res.msg || "操作成功");
          this.insideDialogVisible = false
        }
        this.insideBtnLoading = false
      }).catch((err) => {
        this.insideBtnLoading = false
        if (err.msg.indexOf('口令') > -1) {
          this.$message.error(err.msg);
          return
        }
        this.enableShow = true
        this.errorMsg = err.msg
      });
    },
    usedSuccess() {
      _api
          .symbolUsedCompany({
            companyId: this.usedCompanyId,
            isSymbolUsed: this.usedType,
            command: this.usedCommand,
          })
          .then((res) => {
            if (res.code == 1) {
              this.$message.success(res.msg || "操作成功");
              this.usedDialogVisible = false
            } else {
              this.$message.success(res.msg || "操作成功");
              this.usedDialogVisible = false
            }
          });
    },
    reset() {
      this.isReset = true;
      this.pwdFlag = false;
    },
    //靓机开关事件
    BeautifulSwitch(row) {
      this.isOpenNiceBiz = row.isOpenNiceBiz;
      console.log(row);
      this.companyId = row.id;
      this.isOpenB = row.isOpenPc;
      console.log(
          row.isOpenPc,
          "-----------------------------------------------"
      );
      if (row.isOpenNiceBiz) {
        this.openorclose = "开启提示";
      } else {
        this.openorclose = "关闭提示";
      }
      this.BeautifulShow = !this.BeautifulShow;
    },

    // 弹窗确认按钮
    BeautifulSuccess() {
      if (this.formOpenPc.command == "") {
        this.$message.warning("请输入动态口令");
        return;
      }

      console.log(this.isOpenB);
      if (this.isOpenNiceBiz) {//开启了靓机回收
        _api
            .getNiceMerchant({
              id: this.companyId,
              isOpen: true,
              command: this.formOpenPc.command,
            })
            .then((res) => {
              console.log(res, "确认按钮");
              if (res.code == 1) {
                this.$message.success(res.msg || "操作成功");
                this.BeautifulShow = false;
                console.log(res);
              } else {
                this.$message.success(res.msg || "操作成功");
                this.BeautifulShow = false;
              }
            });
      } else {
        _api
            .getNiceMerchant({
              id: this.companyId,
              isOpen: false,
              command: this.formOpenPc.command,
            })
            .then((res) => {
              this.$message.success(res.msg || "操作成功");
              this.BeautifulShow = false;
              console.log(res);
            });
      }
    },
    BeautifulClosed() {
      // this.dialogVisibleB = !this.dialogVisibleB
      this.BeautifulShow = false;
    },

    //查看事件
    editClickT(row) {
      console.log(row, "查看事件");
      this.clickLogo = "";
      this.addressInfo.provinceID = row.provinceId;
      this.contactPhone = row.majorMobile;
      this.addressInfo.cityID = row.cityId;
      this.addressInfo.areaID = row.regionId;
      this.dialogImageUrl = "";
      this.loginMobile = "";
      // this.storeSendSet="00";//门店发货设置
      this.merchantAddress = row.address;
      this.merchantName = row.companyName;
      this.dialogImageUrl = row.businessLicense;
      this.isOpenNiceBizD = row.isOpenNiceBiz;
      this.companyId = row.id;
      // this.companyName = row.companyName
      this.contactName = row.contact;
      this.BianjiText = true;

      this.provincesMount(row.provinceId, row.cityId, row.regionId);
      // 查询靓机回收商家信息
      _api.getNiceMerchantInfo({ companyId: row.id }).then((res) => {
        if (res.data != null) {
          this.loginMobilebool = true;
          console.log(this.loginMobilebool, "优质");
        } else {
          this.loginMobilebool = false;
          console.log(this.loginMobilebool, "物质");
        }
        console.log(res, "请求");
        this.clickLogo = res.data.businessLicense;
        this.provincesMount(
            res.data.provinceId,
            res.data.cityId,
            res.data.regionId
        );
        this.companyName = res.data.merchantName;
        this.merchantName = this.companyName
            ? this.companyName
            : this.merchantName;
        this.contactName = res.data.contactName
            ? res.data.contactName
            : this.contactName;
        this.contactPhone = res.data.contactPhone;
        this.addressInfo.provinceID = res.data.provinceId;
        this.addressInfo.cityID = res.data.cityId;
        this.addressInfo.areaID = res.data.regionId;
        this.dialogImageUrl = res.data.businessLicense;
        this.loginMobile = res.data.loginMobile;
        this.merchantAddress = res.data.merchantAddress;
        this.storeSendSet = res.data.storeSendSet ? res.data.storeSendSet : "00"//门店发货设置
      });

      //  this.provincesMount( this.addressInfo.provinceID,this.addressInfo.cityID,this.addressInfo.areaID)
    },
    provincesMount(provinceId, cityId, regionId) {
      console.log(0);
      _api.listAudit(0).then((res) => {
        if (res.code === 1) {
          this.optionsProvince = res.data;
        }
      });
      _api.listAudit(provinceId).then((res) => {
        this.optionsCity = res.data;
      });
      _api.listAudit(cityId).then((res) => {
        this.optionsArea = res.data;
      });
    },
    // 添加
    liangjiclose() {
      this.BianjiText = false;
    },
    // 确定--添加
    liangjiadd() {
      // this.checkMobile(this.contactPhone); //调用

      if (this.merchantName == "") {
        this.$message({
          type: "warning",
          message: "请输入正确的商家名称",
        });
        return;
      }

      if (this.contactName == "") {
        this.$message({
          type: "warning",
          message: "请输入正确的联系人",
        });
        return;
      }

      var re = /^1\d{10}$/;
      if (!re.test(this.contactPhone)) {
        this.$message({
          type: "warning",
          message: "请输入正确的手机号",
        });
        return;
      }

      if (this.addressInfo.provinceID == "") {
        this.$message({
          type: "warning",
          message: "请选择省",
        });
        return;
      }

      if (this.addressInfo.cityID == "") {
        this.$message({
          type: "warning",
          message: "请选择市",
        });
        return;
      }

      if (this.addressInfo.areaID == "") {
        this.$message({
          type: "warning",
          message: "请选择区",
        });
        return;
      }

      if (this.merchantAddress == "") {
        this.$message({
          type: "warning",
          message: "请输入详细地址",
        });
        return;
      }
      // this.clickLogo = res.data.businessLicense

      if (!this.dialogImageUrl) {
        this.$message({
          type: "warning",
          message: "请上传营业执照",
        });
        return;
      }

      if (!re.test(this.loginMobile)) {
        this.$message({
          type: "warning",
          message: "请输入正确的登录手机号",
        });
        return;
      }
      // dialogImageUrl
      //       this.addressInfo.provinceId = res.data.provinceId
      //  this.addressInfo.cityId = res.data.cityId
      //  this.addressInfo.regionId = res.data.regionId
      console.log(this.addressInfo);
      // if (!this.isOpenNiceBizD) {
      _api
          .saveNiceMerchant({
            businessLicense: this.dialogImageUrl,
            companyId: this.companyId,
            contactName: this.contactName,
            contactPhone: this.contactPhone,
            loginMobile: this.loginMobile,
            merchantAddress: this.merchantAddress,
            merchantId: this.companyId,
            merchantName: this.merchantName,
            provinceId: this.addressInfo.provinceID,
            cityId: this.addressInfo.cityID,
            regionId: this.addressInfo.areaID,
            storeSendSet: this.storeSendSet
          })
          .then((res) => {
            console.log(res, "______________________________________");
          });
      // }
      this.BianjiText = false;
    },
    payModeDialogClosed() {
      this.dialogPayModeVisible = false;
    },
    // 修改利润模式
    editProfitMode(row) {
      if (row.payMode == "online") {
        this.companyName2 = row.companyName;
        this.dialogPayModeVisible = true;
      } else {
        let type = row.profitMode ? true : false;
        let routeUrl = this.$router.resolve({
          path: "/payment/PaySet",
          query: { companyId: row.id, type, source: "offline" }
        });
        window.open(routeUrl.href, "_blank");
        // this.$router.push({
        //   path: "/payment/PaySet",
        //   query: { companyId: row.id, type, source: "offline" },
        // });
      }
    },
    handlePayMode() {
      let routeUrl = this.$router.resolve({
        path: "/payment/PayApply",
        query: { companyName: this.companyName2 }
      });
      window.open(routeUrl.href, "_blank");

      // this.$router.push({
      //   path: "/payment/PayApply",
      //   query: { companyName: this.companyName2 },
      // });
    },
    // 获取门店列表
    getStoreSelectList() {
      _api.getStoreSelectList({ companyId: this.companyId }).then((res) => {
        if (res.code === 1) {
          this.companyList = res.data;
        }
      });
    },
    searchSetting() {
      this.getStaffList();
    },
    // 职员信息导表
    clickExcel() {
      const params = {
        companyId: this.storeParams.companyId,
        storeId: this.staffParams.storeId,
        staffName: this.staffName,
        staffMobile: this.staffMobile,
        isSubscribe: this.isSubscribe,
        pageNum: this.staffParams.pageNum || 1,
        pageSize: this.staffParams.pageSize,
      };
      this.isExport = true
      _api
          .staffExcel(params)
          .then((res) => {
            console.log(res);
            const content = res;
            const blob = new Blob([content]);
            let reader = new FileReader(); // 创建读取文件对象
            reader.addEventListener("loadend", () => {
              try {
                let data = JSON.parse(reader.result);
                if (data.code === 0) {
                  this.$message.error(data.msg || "服务异常");
                }
              } catch (err) {
                const fileName = "店员列表.xlsx";
                if ("download" in document.createElement("a")) {
                  // 非IE下载
                  const elink = document.createElement("a");
                  elink.download = fileName;
                  elink.style.display = "none";
                  elink.href = URL.createObjectURL(blob);
                  document.body.appendChild(elink);
                  elink.click();
                  URL.revokeObjectURL(elink.href); // 释放URL 对象
                  document.body.removeChild(elink);
                } else {
                  // IE10+下载
                  navigator.msSaveBlob(blob, fileName);
                }
              }
            });
            reader.readAsText(blob, "utf-8");
            this.isExport = false
          })
          .catch((err) => {
            this.isExport = false
            console.log(err);
          });
    },
    // 获取门店应用地址接口
    getStoreUrl(row) {
      _api.installMiniwechat({ companyId: row.id }).then((res) => {
        if (res.code === 1) {
          this.storeUrl = res.data;
          this.storeUrlShow = true;
        }
      });
    },
    // 获取门店应用地址弹窗关闭
    storeUrlClosed() {
      this.storeUrlShow = false;
    },
    copyStoreUrl() {
      const oInput = document.createElement("input");
      oInput.value = this.storeUrl;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message.success("复制成功");
      this.storeUrlShow = false;
    },
    //清除微信信息
    clearWechatmessages(staffId) {
      this.WeChatid = staffId
      this.EnableDialogVisible = true
    },
    //确认清除微信信息
    EnableConfirmDialog(){
      _api.clearWechatInfoByStaff({ staffId: this.WeChatid}).then(res => {
        if (res.code === 1) {
          this.$message.success('清除成功！')
          this.EnableDialogVisible = false
          this.getStaffList();
        }
      })
    },
    // 强制退登录
    forciblyLoginOutShow(staffId) {
      this.loginOutId = staffId
      this.commandShow = true
    },
    forciblyLoginOut() {
      if (!this.loginOutCommand) {
        this.$message.error('请输入动态口令')
        return
      }
      this.butLoading = true
      _api.forciblyLoginOut({ staffId: this.loginOutId, command: this.loginOutCommand }).then(res => {
        if (res.code === 1) {
          this.$message.success('操作成功')
          this.commandShow = false
          this.butLoading = false
        }
      }).catch(err => {
        this.butLoading = false
      })
    },
    //强制退登录弹框关闭
    commandClosed() {
      this.commandShow = false,
          this.loginOutId = ""
      this.loginOutCommand = ""
    },
    setRemark(row) {
      if (row.masterNote) {
        this.RemarksModify(row)
      } else {
        this.RemarksAdd(row)
      }
    },
    // 显示藏报价信息动态口令弹框
    showCommandSubmit() {
      this.hidePriceParams.command = ''
      this.hidePriceCommandShow = true
    },
    // 提交藏报价信息请求
    hidePriceSubmit() {
      if (!this.hidePriceParams.command) {
        this.$message.error('请输入动态口令')
        return
      }
      // this.hidePriceButLoading = true
      _api.waitQuoteHide({ companyId: this.companyId, ...this.hidePriceParams }).then(res => {
        this.$message.success(res.msg || '操作成功')
        if (res.code === 1) {
          // 刷新列表
          this.handleCurrentChange();
          this.hidePriceCommandShow = false;
          this.hidePriceShow = false;
        }
      })
    },
    // 获取门店详情
    getDetail(id) {
      _api.getCompanyById(id).then(r => {
        this.initData = r.data;
        window.sessionStorage.setItem('storeShopEditStore', JSON.stringify(r.data));
        this.$router.push({ path: "/StoreShop/editStore", query: { id } });
      })
    },
    handleOperation(command, row) {
      switch (command) {
        case 'isenable':
          this.Disableopen(row)
          break;
        case 'img':
          this.handleBtn(row)
          break;
        case 'storeurl':
          this.getStoreUrl(row)
          break;
        case 'pricesdown':
          this.openAddPrice(row)
          break;
        case 'editinformation':
          this.getDetail(row.id);
          break;
        case 'invitation':
          this.companyId = row.id
          this.inviteParams.canInviteMerchant = row.canInviteMerchant,
              this.inviteParams.canInvitedByMerchant = row.canInvitedByMerchant,
              this.inviteShow = true
          break;
        case 'hidePrice':
          this.companyId = row.id
          this.hidePriceParams.waitQuoteHideCompany = row.waitQuoteHideCompany,
              this.hidePriceParams.waitQuoteHidePhoneName = row.waitQuoteHidePhoneName,
              this.hidePriceShow = true
          break;
        case 'out':
          this.isOut = row.isOut
          this.companyId = row.id
          this.outType = row.outType
          this.rowOutType = row.outType == 'xm' || row.outType == 'ygx'
          if (row.isOut) {
            this.onelineTitle = "禁用提示"
          } else {
            this.onelineTitle = "启用提示"
          }
          this.onelineVisible = true
          break;
        case 'associationStore':
          this.associationProp = {
            ...this.associationProp,
            show: true,
            code: "",
            cuurentData: row,
            tipText: "是否确定将该商户标记为矩阵商户"
          }
          break;
        default:
          break;
      }
    },
    enableClosed() {
      this.enableShow = false
    },
    enableTipSure() {
      let type = this.insideRow.profitMode ? true : false;
      let routeUrl = this.$router.resolve({
        path: "/payment/PaySet",
        query: { companyId: this.insideRow.id, type, source: this.insideRow.payMode, goback: false }
      });
      this.enableShow = false
      this.insideDialogVisible = false
      window.open(routeUrl.href, "_blank");
    },
    // 邀请设置弹框关闭
    inviteClosed() {
      this.inviteParams = {
        canInviteMerchant: true,
        canInvitedByMerchant: true,
        command: "",
      },
          this.handleCurrentChange(this.pageNum)
    },
    // 邀请设置弹框确定
    inviteSubmit() {
      if (!this.inviteParams.command) {
        this.$message.error('请输入动态口令')
        return
      }
      this.inviteBtnLoading = true
      _api.updateInvite({ companyId: this.companyId, ...this.inviteParams }).then(res => {
        this.$message.success(res.msg || '操作成功')
        this.inviteShow = false
        this.inviteBtnLoading = false
      }).then(() => {
        this.inviteBtnLoading = false
      })
    },
    // 启用外部商家弹框关闭
    onelineClosed() {
      this.command = ""
      this.handleCurrentChange(this.pageNum);
    },
    onelineSubmit() {
      if (!this.outType) {
        this.$message.error('请选择外部渠道')
        return
      }
      if (!this.command) {
        this.$message.error('请输入动态口令')
        return
      }
      this.onelineBtnLoading = true
      let params = {
        command: this.command,
        companyId: this.companyId,
        isOut: this.onelineTitle != '禁用提示',
        outType: this.outType,
      }
      _api.isOutOpt(params).then(res => {
        if (res.code == 1) {
          this.$message.success('操作成功')
          this.onelineVisible = false
        }
        this.onelineBtnLoading = false
      }).catch(() => {
        this.onelineBtnLoading = false
      })
    },
    getMatrix() {
      this.matrixDialogProp = {
        ...this.matrixDialogProp,
        loading: true,
      }
      _api.matrixList(this.matrixDialogProp.params).then(r => {
        const data = r.data || {};
        let records = data.records || [];
        const meta = getMeta(r.data.total, r.data.current, r.data.size);

        this.matrixDialogProp = {
          ...this.matrixDialogProp,
          loading: false,
          meta,
          tabData: records.map(v => setValue(v))
        }
      })
    },
    // 切换矩阵点击
    switerMatrix(v) {
      this.isDivide = null;
      this.checkData = null;
      this.matrixDialogProp = {
        ...this.matrixDialogProp,
        params: {
          ...this.matrixDialogProp.params,
          pageNum: 1,
        },
        selectMatrixId: v.matrixId,
        isAgainPushHigh: v.isMatrixAgainPushHigh,
        show: true,
        currentData: v
      };
      this.currentData = v;
      this.rowdata = v;
      this.getMatrix();
    },
    // 矩阵确定
    matrixConfig() {
      if (!this.matrixDialogProp.selectMatrixId) {
        this.matrixDialogProp = {
          ...this.matrixDialogProp,
          show: false,
        }
      } else {
        let message = "";
        _api.getCompanyInMatrixNoAckOrderNum(this.matrixDialogProp.currentData.id).then(r => {
          const num = r.data || 0;
          this.isDivide = null;
          // if (num > 0) {
          //   message = `当前商户有${num}单待确认，如需切换矩阵报价，系统将自动取消订单。`
          // } else {
          //   message = '当前商户无待确认订单，切换矩阵后将由矩阵回收商参与报价。'
          // }
          // 分转详情
          this.getCompanyAndMerchant(this.rowdata.id, this.checkData.merchantId, () => {
            this.codeProp = {
              ...this.codeProp,
              show: true,
              code: "",
              num
            }
          });
        })
      }
    },

    // 确定选择该操作
    codeConfig() {
      if (!this.codeProp.code) {
        return this.$message.error("请输入谷歌动态码");
      }
      if (!this.isDivide && this.effectiveStatus === '04') {
        return this.$message.error("请选择分转繁星仓库操作");
      }
      _api.saveUnionCompany({
        command: this.codeProp.code,
        companyId: this.matrixDialogProp.currentData.id,
        matrixId: this.matrixDialogProp.selectMatrixId
      }).then(r => {
        this.$message.success("操作成功");
        this.codeProp = {
          ...this.codeProp,
          show: false,
        };
        this.matrixDialogProp = {
          ...this.matrixDialogProp,
          show: false
        };
        if (this.isDivide === '01') {
          this.getCompanyPlatformAddress(this.rowdata.id, () => {
            this.formData.companyId = this.rowdata.companyName;
            this.formData.merchantIds = this.checkData.merchantName
            this.sublicenseDialogVisible = true;
          })
        }
        this.handleCurrentChange(this.pageNum)
      })
    },
    // 矩阵选择切换
    addSelectChange(v) {
      this.matrixDialogProp = {
        ...this.matrixDialogProp,
        selectMatrixId: v.id,
      }
      this.checkData = v;
    },
    //开关切换
    switchChange(v) {
      let tipText;
      if (v) {
        tipText = "是否确认开启推送更高价？";
      } else {
        tipText = "是否确认关闭推送更高价？";
      }
      this.againPushHighProp = {
        ...this.againPushHighProp,
        show: true,
        isPush: v,
        tipText
      }
    },
    // 关闭报价弹窗提示操作
    againPushHighClose() {
      this.againPushHighProp = {
        ...this.againPushHighProp,
        show: false,
      };
      this.matrixDialogProp = {
        ...this.matrixDialogProp,
        isAgainPushHigh: !this.againPushHighProp.isPush,
      }
    },

    // 确认报价修改操作
    againPushHighConfig() {
      _api.matrixAgainPushHigh(this.matrixDialogProp.currentData.id || '', this.againPushHighProp.isPush).then(r => {
        this.againPushHighProp = {
          ...this.againPushHighProp,
          show: false
        };
        this.matrixDialogProp = {
          ...this.matrixDialogProp,
          show: false
        };
        this.$message.success("操作成功");
        this.handleCurrentChange(this.pageNum);
      })

    },

    // 确定
    associationPropConfig() {
      if (!this.associationProp.code) {
        return this.$message.error("请输入动态口令");
      }
      _api.setCompanyIsMatrix(this.associationProp.code, this.associationProp.cuurentData.id).then(r => {
        this.$message.success("操作成功");
        this.handleCurrentChange();
        this.associationProp = {
          ...this.associationProp,
          show: false
        }
      })
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.biliStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.command .el-input {
  width: 80%;
}

.fzs16 {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  text-align: center;
}

.mb20 {
  margin-bottom: 20px;
}

.storeSendSet {
  color: #666666;
  line-height: 40px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  margin-bottom: 20px;
  padding-right: 20px;
}

.fw-500 {
  font-weight: 700;
}

.store-lable {
  display: flex;
  justify-content: space-between;
}

.remark {
  font-size: 12px;
  color: #FF687B
}

.GlobalTable {
  .account {
    font-weight: 500;
  }

  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }

  .handleClass {
    display: inline-block;
    margin-left: 5px;
  }

  .acc_dialog {
    /deep/ .el-input {
      width: 240px;
    }
  }

  .acc_dialog2 {
    height: 500px;

    /deep/ .el-input {
      width: 240px;
      height: 40px;
    }
  }

  .pwd-style {
    cursor: pointer;
  }

  .command {
    display: flex;
    align-items: center;
  }

  .command .el-input {
    width: 80%;
  }

  .command2 {
    display: flex;
    align-items: center;
    width: 700 rpx;
  }
}

.examine {
  display: flex;
  align-items: center;
}

.examine .el-input {
  flex: 5;
  width: 100%;
}

.examine div {
  flex: 1;
}

.examine2 {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.examine2_son {
  flex: 8;
  display: flex;
  align-items: center;
}

.examine2_son2 {
  flex: 9;
  display: flex;
  align-items: center;
}

.examine2 .examine2_son .examine2_son_one {
  text-align: center;
  flex: 1;
}

.examine2 .examine2_son2 .examine2_son_two {
  text-align: center;
  flex: 2;
  margin-right: 5px;
}

/deep/ .examine2 .examine2_son .el-input__inner:nth-child(1) {
  //  background-color: #ccc;
  flex: 3;
  width: 50%;
  margin-left: 38px;
}

/deep/ .examine2 .examine2_son2 .examine2_son_two_iput {
  flex: 9;
  background-color: #fff;
  width: 100%;
}

.address {
  margin-top: 20px;
  display: flex;
  position: relative;
}

.address .addressinput {
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: space-between;
  position: absolute;
  right: 0;
}

.address .addressinput .el-input {
  margin-left: 40px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .shq .el-select>.el-input {
  display: block;
  margin-right: 20px;
  width: 145px;
}

.shuoming {
  margin-bottom: 30 rpx;
}

.nice_dialog .el-form-item {
  text-align-last: left;
}

// 备注说明
.Remarks {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; //控制行数
  overflow: hidden;
}

.staff-list-form {
  .el-input {
    width: 170px;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.flex-jb {
  justify-content: space-between;
}

.flex-as {
  align-items: flex-start;
}

.mb-20 {
  margin-bottom: 20px;
}

.channeltype1 {
  height: 16px;
  background: #8080ff;
  border-radius: 4px;
  padding: 2px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  margin-right: 6px;
}

.channeltype2 {
  height: 16px;
  background: #fba516;
  border-radius: 4px;
  padding: 2px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  margin-right: 6px;
}

.addparto {
  font-size: 12px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #ff8080;
}

.tip-sure {
  margin-bottom: 20px;
  color: #333333;
  font-size: 16px;
  text-align: center;
}

/deep/ .el-table__body-wrapper {
  &::-webkit-scrollbar {
    /* 滚动条整体样式 */
  }
}

/deep/ .gutter {
  display: none;
}

/deep/ .el-checkbox-group {
  // 隐藏 1
  .el-checkbox {
    .el-checkbox__label {
      display: none;
    }
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

.enable-tip {
  color: #FF8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}

.fc_line {
  color: #0981FF;
  text-decoration: underline;
  cursor: pointer;
}

.f-line {
  text-decoration: underline;
}

.tip {
  color: #F93636;
  font-size: 12px;
  margin-bottom: 20px;
}

.matrix-header {
  >h2 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  >div {
    margin-left: 10px;
    margin-bottom: 20px;

    >span {
      margin-right: 10px;
    }
  }

  /deep/ .el-switch__label * {
    line-height: 1;
    font-size: 12px;
    display: inline-block;
  }

  /deep/ .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
    font-size: 12px !important;
  }

  /*打开时文字位置设置*/
  /deep/ .el-switch__label--right {
    z-index: 1;
    right: -7px;
  }

  /*关闭时文字位置设置*/
  /deep/ .el-switch__label--left {
    z-index: 1;
    left: 30px;
  }

  /*显示文字*/
  /deep/ .el-switch__label.is-active {
    display: block;
  }

  /*开关宽度*/
  /deep/ .el-switch .el-switch__core,
  /deep/ .el-switch .el-switch__label {
    width: 50px !important;
  }
}

.ycyc2 {
  // display: flex;
  // flex-wrap: wrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 2;
}

.center_show {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 0 20px 20px;
}

.ad_cstyle {
  width: 100%;
  padding: 15px;
  min-height: 110px;
  background: #F5F6FA;
  border-radius: 14px;
  margin-bottom: 15px;
  margin-top: -20px;

  .adc_top {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .adc_line {
      width: 3px;
      height: 13px;
      background: #0981FF;
      border-radius: 2px;
      margin-right: 5px;
    }

    .xiugai {
      /deep/ .el-button {
        text-decoration: underline;
        padding: 5px 0 5px 10px;
      }
    }

  }
}

.addFenTime {
  width: 100%;
  display: flex;
  align-items: center;

  >div:first-of-type {
    width: 60% !important;
    margin-right: 20px;
  }

  >div:last-of-type {
    flex: auto;
  }
}

.raido_inner {
  display: flex;
  align-items: center;
  cursor: pointer;

  >div {
    border: 1px solid #0981FF;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #fff;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #fff;
    }
  }

  >p {
    font-size: 14px;
    margin-left: 10px;
  }

  .active_select {
    border-color: #0981FF;
  }

  .active_select::after {
    background: #0981FF;
  }
}

.actionstyle {
  margin: 20px 0;
}

.displaghf {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.im_actin {
  img {
    width: 15px;
    height: 15px;
  }
}

.code_message_box{
  color:#FF7586;
  margin-bottom: 20px;
}
.code-dialog-box {
  /deep/ .message{
    margin-top:  0;
    padding-left: 0;
  }
  /deep/ .message::before{
    width:0;
  }
}
</style>
