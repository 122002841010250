<template>
  <div class="GlobalFormDemo">
    <GlobalForm
        :init-data="initData"
        :form-rules="formRules"
        :form-item-list="formItemList"
        :inline="true"
        round
        @handleChange="handleChange"
        @handleConfirm="handleConfirm"
        confirmBtnName="查询"
    >
      <el-form-item
          slot="companyName"
          class="el_from"
          :label-width="'80px'"
          label="商家名称"
      >
        <el-input v-model="companyName" placeholder="请输入商家名称"></el-input>
      </el-form-item>
<!--      <el-form-item-->
<!--          class="el_from"-->
<!--          :label-width="'80px'"-->
<!--          label="报价矩阵"-->
<!--          slot="matrixSelect"-->
<!--      >-->
<!--        <el-select clearable filterable v-model="matrixId" placeholder="请选择矩阵名称查询">-->
<!--          <el-option-->
<!--              v-for="item in matrixSelectList"-->
<!--              :key="item.value"-->
<!--              :label="item.label"-->
<!--              :value="item.value"-->
<!--          >-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <el-form-item
          class="el_from"
          :label-width="'80px'"
          label="排序方式"
          slot="orderType"
          style="margin: 0"
      >
        <el-select v-model="orderType" placeholder="请选择排序方式">
          <el-option
              v-for="item in sortOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
          class="el_from"
          :label-width="'100px'"
          label="累计订单量"
          slot="orderNum"
          style="margin: 0"
      >
        <el-input style="width: 120px" v-model="startRecycles" min="0" type="number" clearable/>
      </el-form-item>
      <el-form-item
          class="el_from"
          :label-width="'0px'"
          slot="orderNum"
          style="margin: 0;position: relative;margin-left: 20px;"
      >
        <i style="font-size: 22px;position: absolute;left: -17px;top: 3px">~</i>
        <el-input style="width: 120px" v-model="endRecycles" type="number" clearable/>
      </el-form-item>
<!--      <div slot="AddSlot">-->
<!--        <el-button-->
<!--            size="small"-->
<!--            type="success"-->
<!--            @click="openAddPrice"-->
<!--        >平台加价设置-->
<!--        </el-button-->
<!--        >-->
<!--        &lt;!&ndash; <el-button size="small" type="warning" @click="setAddress"-->
<!--        >收货地址设置-->
<!--        </el-button-->
<!--        > &ndash;&gt;-->
<!--        <el-button-->
<!--            size="small"-->
<!--            type="primary"-->
<!--            icon="el-icon-plus"-->
<!--            @click="addOneMenu"-->
<!--        >添加商家-->
<!--        </el-button-->
<!--        >-->
<!--      </div>-->
    </GlobalForm>

    <el-dialog
        class="address_dialog"
        title="收货地址设置"
        :visible.sync="setAddressShow"
        :close-on-click-modal="false"
        width="500px"
        @closed="setAddressClosed"
    >
      <div style="margin-bottom: 20px">
        <span style="color: #ff687b; font-size: 16px"
        >说明：该收货地址用于设置收货方为平台的商户，员工在线下发货查看地址与线上发货时使用。</span
        >
      </div>
      <el-form
          label-width="97px"
          :model="addressForm"
          :rules="addressFormRules"
          ref="addressFormRules"
      >
        <el-form-item label="收货人姓名:" prop="contactName">
          <el-input
              size="small"
              clearable
              v-model="addressForm.contactName"
              placeholder="请输入收货人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="收货人电话:" prop="contactPhone">
          <el-input
              size="small"
              clearable
              v-model.number="addressForm.contactPhone"
              placeholder="请输入收货人电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="详细地址:" prop="merchantAddress">
          <el-input
              size="small"
              clearable
              maxlength="30"
              v-model="addressForm.merchantAddress"
              placeholder="请输入详细地址，最多30个字"
          ></el-input>
        </el-form-item>
        <el-form-item label="门牌号:">
          <el-input
              size="small"
              maxlength="10"
              clearable
              v-model="addressForm.houseAddress"
              placeholder="请输入门牌号，最多10个字"
          ></el-input>
        </el-form-item>
        <el-form-item label="动态口令:" prop="command">
          <el-input
              size="small"
              clearable
              v-model.trim="addressForm.command"
              placeholder="请输入谷歌动态口令"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="setAddressShow = false">取消</el-button>
        <el-button
            type="primary"
            :loading="butLoading"
            @click.native="addressSubmit"
        >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 原加价设置弹窗 -->
    <!--    <el-dialog-->
    <!--        title="平台加价设置"-->
    <!--        :visible.sync="addPriceShow"-->
    <!--        :close-on-click-modal="false"-->
    <!--        width="800px"-->
    <!--    >-->
    <!--      <div>-->
    <!--        <span-->
    <!--            style="color: #ff687b;font-size: 14px;">1.开启后仅零售通渠道订单支持加价，平台发起报价按照提交时商家最高成交价金额计算提交的报价；</span>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <span style="color: #ff687b;font-size: 14px;">2.用于平台加价的回收商需要通过数据库配置。</span>-->
    <!--      </div>-->
    <!--      <div class="flex mt-20" style="color:#333;font-size:16px">-->
    <!--        <div style="margin-right:10px;">开启平台加价功能：</div>-->
    <!--        <el-switch-->
    <!--            v-model="isOpen"-->
    <!--            active-text="开"-->
    <!--            inactive-text="关"-->
    <!--            active-value="01"-->
    <!--            inactive-value="00"-->
    <!--        >-->
    <!--        </el-switch>-->
    <!--      </div>-->
    <!--      <div class="mt-20" style="color:#333;font-size:16px">-->
    <!--        <span>平台加价比例设置</span>-->
    <!--        <span-->
    <!--            style="color: #ff687b;font-size: 14px;margin-left:30px">计算公式：平台报价=（1+加价比例）×最高商家成交价</span>-->
    <!--      </div>-->
    <!--      <GlobalTable-->
    <!--          class="mt-20"-->
    <!--          style='padding-bottom:20px;'-->
    <!--          ref="GlobalTable"-->
    <!--          :columns="regionColumns"-->
    <!--          :data="regionList"-->
    <!--          :maxHeight="500"-->
    <!--          :isPagination="false"-->
    <!--      >-->
    <!--        <el-table-column label="最高成交价区间" width="135px" slot="rank" align="center">-->
    <!--          <template slot-scope="{ row }">-->
    <!--            <span>{{ row.lower }}＜区间≤{{ row.upper }}</span>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column label="加价比例" slot="markUpRate" align="center">-->
    <!--          <template slot-scope="{ row }">-->
    <!--            <span>{{ row.markUpRate }}%</span>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column label="加价上限" slot="markUpLimit" align="center">-->
    <!--          <template slot-scope="{ row }">-->
    <!--            <span>￥{{ row.markUpLimit }}</span>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column label="操作" slot="operation" align="center">-->
    <!--          <template slot-scope="{ row }">-->
    <!--            <el-button type="primary" size="mini" @click="editConfig(row)">编辑</el-button>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--      </GlobalTable>-->
    <!--      <span slot="footer" class="dialog-footer">-->
    <!--        <el-button @click.native="addPriceShow = false">取消</el-button>-->
    <!--        <el-button type="primary" @click.native="submitAddPrice()"-->
    <!--        >确定</el-button>-->
    <!--      </span>-->
    <!--    </el-dialog>-->
    <el-dialog
        title='编辑加价规则'
        :visible.sync="editPriceShow"
        :close-on-click-modal="false"
        width="400px"
        @closed="editPriceClosed"
    >
      <el-form
          :model="editPriceForm"
          :rules="editPricerules"
          ref="editPriceRulesForm"
          label-width="83px"
      >
        <el-form-item label="最高报价区间:" label-width="100px">
          <el-col :span="11">
            <el-form-item>
              <el-input
                  size="small"
                  v-model.trim="editPriceForm.lower"
                  disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col style="text-align: center" :span="2">~</el-col>
          <el-col :span="11">
            <el-form-item>
              <el-input
                  size="small"
                  v-model.trim="editPriceForm.upper"
                  disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="加价比例:" prop="markUpRate">
          <el-input
              size="small"
              placeholder="输入加价比例"
              v-model.trim="editPriceForm.markUpRate"
              @input="limitInput($event,'markUpRate')"
              @blur="inputBlur('markUpRate')"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="加价上限:" prop="markUpLimit">
          <el-input
              size="small"
              placeholder="输入加价上限"
              maxlength="5"
              v-model.trim="editPriceForm.markUpLimit"
              @input="(val) => (editPriceForm.markUpLimit = val.replace(/\D/g, '').replace(/^0{1,}/g, ''))"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="editPriceShow = false">取消</el-button>
        <el-button type="primary" @click.native="submitEditPrice()"
        >确定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
        title='操作提示'
        :visible.sync="commandShow"
        :close-on-click-modal="false"
        width="400px"
        @closed="commandClosed"
    >
      <div class="tip-sure">是否确认修改平台加价配置？</div>
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input
            v-model="command"
            placeholder="请输入谷歌动态口令"
            @input="(val) => (command = val.replace(/[^\d]/g, ''))"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandShow = false">取消</el-button>
        <el-button type="primary" :loading="commandBtnLoading" @click.native="commandSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";

let checkNum = (rule, value, callback) => {
  if (value == 0 || value > 100) {
    callback(new Error('加价比例必须大于0小于等于100'))
  } else {
    callback()
  }
}
export default {
  name: "Form",
  data() {
    const {matrixName} = this.$route.query;
    return {
      // 矩阵下拉
      matrixSelectList: [],
      initData: null,
      companyName: "",
      matrixId: matrixName,
      contact: "",
      phone: "",
      orderType: "01",
      // 最大订单数
      startRecycles: "",
      endRecycles: "",
      formItemList: [
        // {
        //   key: "companyName",
        //   type: "input",
        //   labelName: "商家名称",
        //   placeholder: "请输入商家名称",
        // },
        {slotName: "companyName"},
        {
          key: "contact",
          type: "input",
          labelName: "联系人",
          placeholder: "请输入联系人",
        },
        {
          key: "phone",
          type: "input",
          labelName: "联系电话",
          placeholder: "请输入联系电话",
        },
        {
          labelName: "省市查询",
          key: 'provinceId',
          //需要被清除的key，相当于子
          childrenKey: "cityId",
          type: "selectFilterable",
          placeholder: "请选择",
          lableWidth: '80px',
          clear: true,
          option: [],
          clearFun: () => false
        },
        {
          labelName: "",
          key: 'cityId',
          fatherKey: "provinceId",
          type: "selectFilterable",
          placeholder: "请先选择省",
          lableWidth: '0',
          clear: true,
          option: [],
          disabled: true,
          clearFun: () => false
        },
        {
          key: "channel",
          type: "select",
          lableWidth: "80px",
          labelName: "注册渠道",
          placeholder: "请选择注册渠道",
          option: [
            {label: "全部", value: ""},
            {label: "后台", value: "01"},
            {label: "小程序", value: "02"},
          ],
        },
        // {
        //   key: "isSymbolUsed",
        //   type: "select",
        //   lableWidth: "120px",
        //   labelName: "是否正式启用",
        //   option: [
        //     {
        //       value: "",
        //       label: "全部",
        //     },
        //     {
        //       value: true,
        //       label: "是",
        //     },
        //     {
        //       value: false,
        //       label: "否",
        //     },
        //   ],
        // },
        // {
        //   key: "isOpenPc",
        //   type: "select",
        //   lableWidth: "140px",
        //   labelName: "是否启用门店后台",
        //   option: [
        //     {
        //       value: "",
        //       label: "全部",
        //     },
        //     {
        //       value: true,
        //       label: "是",
        //     },
        //     {
        //       value: false,
        //       label: "否",
        //     },
        //   ],
        // },
        {
          slotName: "matrixSelect"
        },
        {slotName: "orderType"},
        {slotName: "orderNum"},
        {slot: "AddSlot"},
      ],
      sortOption: [
        {
          value: "01",
          label: "按添加时间",
        },
        {
          value: "02",
          label: "按门店数量",
        },
      ],
      formRules: {
        staffName: [],
      },
      setAddressShow: false,
      butLoading: false,
      addressForm: {
        contactName: "",
        contactPhone: "",
        merchantAddress: "",
        houseAddress: "",
        command: "",
      },
      addressFormRules: {
        contactName: [
          {required: true, message: "请输入收货人姓名", trigger: "blur"},
        ],
        contactPhone: [
          {required: true, message: "请输入收货人电话", trigger: "blur"},
        ],
        merchantAddress: [
          {required: true, message: "请输入详细地址", trigger: "blur"},
          {max: 30, message: "最多30个字", trigger: "blur"},
        ],
        command: [
          {required: true, message: "请输入谷歌动态口令", trigger: "blur"},
        ],
      },
      addPriceShow: false,
      regionList: [],
      isOpen: false,
      regionColumns: [
        {slotName: "rank"},
        {slotName: "markUpRate"},
        {slotName: "markUpLimit"},
        {slotName: "operation"},
      ],
      commandShow: false,
      command: "",
      commandBtnLoading: false,
      editPriceShow: false,
      rowId: "",
      editPricerules: {
        markUpRate: [
          {required: true, message: "请输入加价比例", trigger: "blur"},
          {type: "number", validator: checkNum, trigger: "blur"},
        ],
        markUpLimit: [
          {required: true, message: "输入加价上限金额", trigger: "blur"}
        ],
      },
      editPriceForm: {
        lower: "",
        upper: "",
        markUpRate: "",
        markUpLimit: "",
      }
    };
  },
  created() {
    if (this.$route.query.companyName) {
      this.companyName = this.$route.query.companyName;
    }
    // this.getSelectMatrixList();
    this.handleConfirm();
    this.getProvince();
  },
  methods: {
    // 获取省市区
    getProvince(){
      _api.listAudit(0).then(r=>{
        const index = this.formItemList.findIndex(v=>v.key==='provinceId');
        this.formItemList[index].option = r.data.map(v=>{
          return{
            label:v.cityName,
            value:v.id
          }
        })
      })
    },
    // 获取市区
    getCity(v){
      _api.listAudit(v).then(r=>{
        const index = this.formItemList.findIndex(v=>v.key==='cityId');
        console.log(v);
        this.formItemList[index].option = r.data.map(v=>{
          return{
            label:v.cityName,
            value:v.id
          }
        })
      })
    },
    // 搜索项变化
    handleChange(v1, v2) {
      if (v1.key === "provinceId") {
        const index = this.formItemList.findIndex(v => v.key === v1.childrenKey);
        if (v2) {
          this.formItemList[index].disabled = false;
          this.formItemList[index].placeholder = "请选择市"
          this.getCity(v2);
        } else {
          this.formItemList[index].placeholder = "请先选择省"
          this.formItemList[index].disabled = true;
        }
      }
    },
    addOneMenu() {
      window.sessionStorage.removeItem('storeShopEditStore');
      this.$router.push("/StoreShop/Addstore");
    },
    // 矩阵下拉
    // getSelectMatrixList() {
    //   _api.getSelectMatrixList().then(r => {
    //     const data = r.data || [];
    //     this.matrixSelectList = data.map(v => {
    //       return {
    //         label: v.name,
    //         value: v.id
    //       }
    //     })
    //   })
    // },
    handleConfirm(data) {
      if (data) {
        // this.companyName = data.companyName;
        this.contact = data.contact;
        this.phone = data.phone;
        this.channel = data.channel;
        this.isSymbolUsed = data.isSymbolUsed;
        this.isOpenPc = data.isOpenPc;

      } else {
        // this.companyName = "";
        this.contact = "";
        this.phone = "";
        this.channel = "";
        this.isSymbolUsed = "";
        this.isOpenPc = "";
      }
      if (parseInt(this.startRecycles) < 0) {
        this.startRecycles = 0;
        return this.$message.error("开始累计订单数不能小于0");
      }
      if (parseInt(this.endRecycles) < 0) {
        return this.$message.error("结束累计订单数不能小于0");
      }
      if (parseInt(this.startRecycles) > parseInt(this.endRecycles)) {
        return this.$message.error('累计订单数量范围不正确');
      }
      const {matrixName, matrixId} = this.$route.query;
      console.log(data)
      const SeachParams = {
        merchantType: "01",
        companyName: this.companyName,
        contact: this.contact,
        phone: this.phone,
        companyId: 0,
        pageNum: 1,
        pageSize: 10,
        channel: this.channel,
        isSymbolUsed: this.isSymbolUsed,
        isOpenPc: this.isOpenPc,
        orderType: this.orderType,
        startRecycles: parseInt(this.startRecycles),
        endRecycles: parseInt(this.endRecycles),
        matrixId: this.matrixId === matrixName ? matrixId : this.matrixId,
        cityId:data?data.cityId:undefined,
        provinceId:data?data.provinceId:undefined
      };
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    // 设置收货地址
    // setAddress() {
    //   _api.queryFormAddress().then((res) => {
    //     if (res.code == 1) {
    //       this.addressForm = {
    //         contactName: res.data.contactName,
    //         contactPhone: res.data.contactPhone,
    //         merchantAddress: res.data.merchantAddress,
    //         houseAddress: res.data.houseAddress,
    //         command: "",
    //       };
    //     }
    //   });
    //   this.setAddressShow = true;
    // },
    // 设置收货地址弹框关闭
    setAddressClosed() {
      this.$refs["addressFormRules"].clearValidate();
    },
    // 确定设置地址
    // addressSubmit() {
    //   this.$refs["addressFormRules"].validate((valid) => {
    //     if (valid) {
    //       this.butLoading = true;
    //       _api
    //           .savePlatformAddress(this.addressForm)
    //           .then((res) => {
    //             if (res.code == 1) {
    //               this.$message.success(res.msg || "操作成功");
    //               this.setAddressShow = false;
    //             }
    //             this.butLoading = false;
    //           })
    //           .catch((err) => {
    //             this.$message.error(err.msg || "操作失败");
    //             this.butLoading = false;
    //           });
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    // 打开加价设置弹框
    openAddPrice() {
      this.$router.push("/StoreShop/priceMarkup");
      // 原弹窗操作
      // this.addPriceShow = true;
      // _api.getMakeupConfig().then(res => {
      //   if (res.code == 1) {
      //     console.log(res);
      //     this.isOpen = res.data.isOpen,
      //         this.regionList = res.data.regionList
      //   }
      // })
    },
    // 加价弹框确认
    submitAddPrice() {
      this.commandShow = true
    },
    // 加价配置编辑
    editConfig(row) {
      this.editPriceShow = true
      this.rowId = row.id
      this.editPriceForm = {
        lower: row.lower,
        upper: row.upper,
        markUpRate: row.markUpRate,
        markUpLimit: row.markUpLimit,
      }
    },
    commandClosed() {
      this.command = ""
    },
    editPriceClosed() {
      this.$refs["editPriceRulesForm"].resetFields();
      this.rowId = ""
      this.editPriceForm = {
        lower: "",
        upper: "",
        markUpRate: "",
        markUpLimit: "",
      }
    },
    // 编辑弹框确认
    submitEditPrice() {
      this.$refs["editPriceRulesForm"].validate((valid) => {
        if (valid) {
          this.regionList.forEach(item => {
            if (item.id == this.rowId) {
              item.markUpRate = this.editPriceForm.markUpRate
              item.markUpLimit = this.editPriceForm.markUpLimit
            }
          })
          this.editPriceShow = false
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // commandSubmit() {
    //   if (!this.command) {
    //     this.$message.error('请输入动态口令')
    //     return
    //   }
    //   this.commandBtnLoading = true
    //   let data = {
    //     command: this.command,
    //     isOpen: this.isOpen,
    //     regionList: this.regionList,
    //   }
    //   _api.saveMakeupConfig(data).then(res => {
    //     if (res.code == 1) {
    //       this.$message.success(res.msg || '操作成功')
    //       this.commandShow = false
    //       this.addPriceShow = false
    //     }
    //     this.commandBtnLoading = false
    //   }).catch(() => {
    //     this.commandBtnLoading = false
    //   })
    // },
    limitInput(value, name) {
      let str = (value && value.split("")) || [];
      let reg1 = /\d/;
      let reg2 = /\./;
      // 第一个字符不能为小数点
      if (str[0] == ".") {
        this.editPriceForm[name] = ""
        return;
      }
      // 过滤掉除数字和小数点外的字符
      value = str.filter((e) => reg1.test(e) || reg2.test(e));
      // 匹配小数点后只能有两位小数
      let valJoin = value.join("");
      this.editPriceForm[name] = valJoin.match(/^\d*(\.?\d{0,2})/g)[0] || "";
      if (this.editPriceForm[name] > 100) {
        this.editPriceForm[name] = '100'
      }
    },
    // 失焦
    inputBlur(name) {
      console.log(this.editPriceForm[name]);
      if (this.editPriceForm[name].toString().indexOf('.') == (this.editPriceForm[name].length - 1)) {
        this.editPriceForm[name] = this.editPriceForm[name].replace('.', '')
      }
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  .address_dialog {
    /deep/ .el-form-item {
      margin-bottom: 10px;
    }

    /deep/ .el-form-item__error {
      padding: 0px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .mt-20 {
    margin-top: 20px;
  }

  /deep/ .el-input-group__append {
    border: 1px solid #0981ff;
    background: #0981ff;
    color: #ffffff;
  }

  /deep/ .demo-ruleForm .el-form-item__content .el-input-group {
    vertical-align: middle;
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
}

//去除数字框上下箭头
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

/deep/ input[type='number'] {
  -moz-appearance: textfield;
}
</style>
